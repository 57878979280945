.gh-page--crisis {
    .grecaptcha-badge {
        z-index: 1;
    }
    
    .gh-hero {
        background: url('/images/app/crisis/Covid-19-Banner-Image.jpg') no-repeat left center;
        background-size: cover !important;
    }

    .gh-section {
        padding: 3rem 0;
    }

    .gh-hero__container {
        position: relative;
        height: 360px;
    }

    .gh-hero__content {
        @include m-absolute();

        width: 100%;
        color: #085078;
    }

    .gh-hero__heading {
        margin-bottom: 1rem;
        font-size: 2.3rem;
        font-weight: 700;
        color: white;
        line-height: 1.2;
    }

    .intro {
        text-align: center;

        &__content {
            position: relative;
        }

        &__text {
            color: #514A9D;
            font-size: 1.7rem;
            line-height: 1.5;
        }
    }

    .gh-bg-white {
        background-color: white;
    }

    .gh-pt-0 {
        padding-top: 0;
    }

    .gh-pb-0 {
        padding-bottom: 0;
    }

    @include m-respond-to('tablet') {
        .gh-hero__content {
            left: 10px;
            width: 60%;
            transform: translate3d(0, -50%, 0);
        }

        .intro {
            &__text {
                font-size: 1.9rem;
            }
        }
    }

    @include m-respond-to('notebook') {
        .gh-hero {
            background: url('/images/app/crisis/Covid-19-Banner-Image.jpg') no-repeat left center;
            background-position: center center;

            &__container {
                height: 550px;
            }

            &__heading {
                font-size: 3.3rem;
            }

            &__subheading {
                font-size: 1.7rem;
            }

            .gh-button--cta {
                margin-top: 1.5rem;
            }
        }

        .intro {
            position: relative;

            &__quote {
                position: absolute;
                display: block;
                max-width: 50px;

                &--left {
                    top: -15px;
                    left: -10px;
                }

                &--right {
                    right: 210px;
                    bottom: -5px;
                }
            }
        }
    }
    @include m-respond-to('desktop') {
        .intro {
            &__text {
                font-size: 2rem;
            }
        }
    }
}
