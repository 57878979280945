.gh-page {
    .gh-page-intro {
        padding: 3.5rem 0 2rem;
        padding-top: 2rem;

        .gh-section__heading {
            color: $pallete-dark-blue;
            text-align: left;
            margin-bottom: 2rem;
        }
    
        .gh-section__subheading {
            max-width: 600px;
            color: $pallete-dark-blue;
            font-weight: 600;
            font-size: 1.45rem;
            line-height: 1.4;
        }
        @media (min-width: 768px) {
            padding-top: 3.5rem;
        }
    }
}