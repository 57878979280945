button {
    border: 0;

    &:focus {
        outline: none;
    }
}

.gh-button-redesign {
    position: relative;
    display: inline-block;
    padding: 1rem 3.4rem;
    border: 0;
    border-radius: 2px;
    color: $pallete-dark-grey;
    font-size: 1rem;
    line-height: 1.2;
    text-align: center;
    transition: .3s;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

//==================================================
//                 [-- OUTLINED --]
//==================================================
.gh-button-redesign--primary {
    border: 1px solid $pallete-dark-grey;
    background: transparent;
    padding: 1rem 3.4rem;
    font-weight: 700;
    &::before,
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
        content: '';
        transform: scale(0);
        transition: .3s;
    }

    &::before {
        border-bottom: 2px solid $brand-secondary;
        border-left: 2px solid $brand-secondary;
        transform-origin: 0 100%;
    }

    &::after {
        border-top: 2px solid $brand-secondary;
        border-right: 2px solid $brand-secondary;
        transform-origin: 100% 0;
    }

    &:hover {
        border-color: rgba(#565a60, 0);

        &::before,
        &::after {
            transform: scale(1);
        }
    }
}

//==================================================
//                 [-- CTA --]
//==================================================
.gh-button-redesign--cta {
    color: #fff;
    background: $brand-secondary;

    &:hover,
    &:focus {
        color: #fff;
        background: #4a4489;
    }

    &.is-disabled {
        background: #403c76;

        &:hover {
            background: #403c76;
        }
    }
}

//==================================================
//                 [-- SILVER --]
//==================================================
.gh-button-redesign--silver {
    color: #fff;
    background: #8391a5;

    &:hover,
    &:focus {
        color: #fff;
        background: #9DA8BF;
    }
}