.gh-dashboard--assessment {
    p {
        + p {
            margin-top: 1rem;
        }
    }
}

//==================================================P
//                 [-- COMPLETION --]
//==================================================
.gh-assessment__completion {
    .overview {
        margin-bottom: 2rem;

        &__header {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;

            .icon {
                max-width: 50px;
                margin-right: 1rem;
            }

            .label {
                color: $brand-secondary;
                font-weight: 500;
                font-size: 2rem;
            }
        }

        &__body {
            ul {
                padding-left: 1.5rem;
                list-style-type: disc;

                ul {
                    list-style-type: circle;
                }
            }
        }
    }

    .action {
        + .action {
            margin-top: 2rem;
        }

        &__heading {
            margin-bottom: .5rem;
            color: #474f59;
            font-size: 1.5rem;
        }

        .gh-button {
            margin-top: .5rem;
        }
    }
}

//==================================================
//                 [-- QUESTIONS --]
//==================================================
.gh-assessment__questions {
    .intro {
        margin-bottom: 2rem;
        padding: 1rem;
        border: 1px dashed $brand-secondary;
        background: #fff;
    }

    .actions {
        margin-top: 2rem;
    }
}

.gh-question {
    + .gh-question {
        margin-top: 2rem;
    }

    &__content {
        padding: 1rem;
        background: #fff;

        &.leading-text {
            margin-bottom: 2rem;
            border: 1px dashed $brand-primary;
            background: #def6fa;
            font-weight: 500;
        }
    }

    &__statement {
        margin-bottom: 1rem;
        font-weight: 500;
    }

    &__choices {
        margin-bottom: 2rem;

        .el-radio {
            + .el-radio {
                margin: .5rem 0 0;
            }
        }

        .el-radio__label {
            display: flex;
            font-weight: 500;

            .choice__prefix {
                margin-right: .5rem;
            }
        }

        .el-radio__input {
            &.is-disabled {
                cursor: default;

                + .el-radio__label,
                .el-radio__inner {
                    color: inherit;
                    cursor: default;
                }

                &.is-checked {
                    .el-radio__inner {
                        border-color: $brand-primary;
                        background-color: $brand-primary;
                    }
                }
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__hint {
        color: $brand-secondary;

        .correct-answer {
            font-weight: 600;
            font-size: 1.5rem;
        }
    }
}