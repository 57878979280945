.gh-form {
    &__wrapper {
        position: relative;
    }

    &__label {
        display: block;
        margin-bottom: 3px;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1;
    }

    .el-input__inner {
        background: #e5e5e5;
    }

    .el-textarea__inner {
        border-radius: 0;
        background: #e5e5e5;
    }

    .el-form-item__error {
        font-size: 14px;
    }
}

.gh-form__response {
    padding: 1rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.3;

    &.is-success {
        color: #fff;
        background: #13ce66;
    }

    &.is-error {
        color: #fff;
        background: #cd5c5c;
    }

    .el-alert {
        border-radius: 0;
    }
}

//==================================================
//                 [-- NEWSLETTER FORM --]
//==================================================
.gh-form--newsletter {
    .form__header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }
}

input[name="cardName"] {
    background: #fff !important;
}
