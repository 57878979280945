.gh-faqs {
    // Selector
    &__selector {
        .el-input__inner {
            background: #fff;
        }
    }

    // Listing
    &__listing {
        .el-collapse {
            border: 0;
        }

        .el-collapse-item {
            margin-bottom: .5rem;
            border: 1px solid #cbccce;
            color: #4d5152;

            &.is-plain {
                margin-bottom: 0;
                border: 0;
                border-bottom: 1px solid #cbccce;

                .el-collapse-item__header {
                    align-items: flex-start;
                    padding: 1rem 0;
                    background: transparent;
                }
            }

            &.is-active {
                .el-collapse-item__header {
                    color: $brand-primary;
                }
            }
        }

        .el-collapse-item__header {
            display: flex;
            justify-content: space-between;
            height: auto;
            padding: 1rem;
            border-bottom: 0;
            background: #f3f3f3;
            font-weight: 600;
            font-size: 1.1rem;
            line-height: 1;
        }

        .el-collapse-item__header__arrow {
            order: 2;
            margin: 0 10px;
        }

        .el-collapse-item__wrap {
            border-top: 1px solid #dfe6ec;
            border-bottom: 0;
        }

        .el-collapse-item__content {
            padding: 1rem;
            background: #fbfdff;
            font-size: 1rem;

            p {
                line-height: 1.5;
            }
        }
    }
}
