//==================================================
//                 [-- PUFF OUT CENTER --]
//==================================================
.puff-out-center {
    animation: puff-out-center .3s cubic-bezier(0.165, 0.840, 0.440, 1) both;
}

@keyframes puff-out-center {
    from {
        opacity: 1;
        transform: scale(1);
        filter: blur(0);
    }

    to {
        opacity: 0;
        transform: scale(2);
        filter: blur(2px);
    }
}

//==================================================
//                 [-- PUFF IN CENTER --]
//==================================================

.puff-in-center {
    animation: puff-in-center .3s cubic-bezier(0.470, 0, 0.745, 0.715) both;
}

@keyframes puff-in-center {
    from {
        opacity: 0;
        transform: scale(2);
        filter: blur(2px);
    }

    to {
        opacity: 1;
        transform: scale(1);
        filter: blur(0);
    }
}