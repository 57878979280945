.gh-dashboard--course {
    .gh-sidebar__menu {
        .el-menu-item {
            border: 0;
            cursor: pointer;
        }

        > .el-menu-item {
            border-left: 5px solid;

            a {
                padding: 1rem;
            }

            &.is-cyan {
                border-left-color: $brand-primary;
            }

            &.is-purple {
                border-left-color: $brand-secondary;
            }
        }

        .gh-submenu {
            &.is-active {
                border-left-width: 5px;
            }

            .el-submenu__title {
                padding: 1rem !important;
            }

            .el-menu-item {
                a {
                    padding: 1rem;
                    font-weight: inherit;
                    transition: .15s;
                }

                &.is-active {
                    background: #d1dbe5;
                    font-weight: 600;
                }

                .gh-menu__label {
                    color: #2f3138;
                }
            }
        }
    }

    .course-info {
        display: none;
    }

    .course-menu {
        .gh-button {
            width: 100%;
            margin-top: 2rem;
        }
    }

    @include m-respond-to('notebook') {
        .course-info {
            display: block;
            margin-top: 1rem;

            .gh-table {
                width: 100%;
                padding: 0 .75rem;
                background: #fff;
            }

            .info {
                display: block;
                padding: .75rem 0;

                + .info {
                    border-top: 1px solid #ccc;
                }

                &__label {
                    font-weight: 600;
                }
            }
        }
    }
}