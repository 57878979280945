.gh-btt {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    z-index: get-value-of($z-index, 'back-to-top-button');
    visibility: hidden;
    border-radius: 4px;
    background: rgba($brand-secondary, .85);
    opacity: 0;
    font-size: 1.5rem;
    cursor: pointer;
    transition: .5s;

    &.is-active {
        visibility: visible;
        opacity: 1;
    }

    &.is-overlapped {
        bottom: 7rem
    }
}

.gh-btt__icon {
    padding: .5rem;
    color: #fff;
    transition: .3s;
}
