@mixin m-absolute($axis: 'both', $to: false, $value: 0) {
    position: absolute;

    @if $axis == 'x' {
        left: 50%;
        transform: translate(-50%, 0);

        @if $to == 'bottom' {
            bottom: $value;
        } @else {
            top: $value;
        }
    }

    @if $axis == 'y' {
        top: 50%;
        transform: translate(0, -50%);

        @if $to == 'right' {
            right: $value;
        } @else {
            left: $value;
        }
    }

    @if $axis == 'both' {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin m-respond-to($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);

    @media screen and (min-width: $value) {
        @content;
    }
}
