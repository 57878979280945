.gh-page--login {
    .gh-page__heading {
        margin-bottom: 2rem;
    }

    .gh-form--login {
        max-width: 400px;
        margin: 0 auto 2rem;
    }

    .gh-form__response {
        margin-bottom: 2rem;
    }
}
