.gh-scout {
    .el-autocomplete {
        width: 100%;

        input {
            padding: 0 1rem;
        }
    }

    .el-input__inner {
        background: #fff;
    }

    .el-input__icon {
        color: $brand-secondary;
    }

    &__item {
        padding: .5rem;

        + .gh-scout__item {
            border-top: 1px solid #ccc;
        }
    }

    @include m-respond-to('notebook') {
        &__results {
            width: 660px !important;
        }

        &__item {
            cursor: pointer;

            + .gh-scout__item {
                border-top: 1px solid #ccc;
            }

            em {
                background: #cdf2f7;
            }

            .course {
                &__title {
                    font-weight: 600;
                }

                &__summary {
                    padding: 0 1rem;
                    line-height: 1.5;
                }
            }
        }
    }
}