.gh-page--events {
    .gh-section {
        color: #555a60;

        &__heading {
            margin-bottom: 1rem;
        }

        &__subheading {
            margin-bottom: 1rem;
            color: #2a3038;
            font-size: 1.8rem;
            text-align: center;
        }
    }

    .intro {
        padding-bottom: 2rem;

        &__text {
            margin: 0 auto;
            color: #2a3038;
            font-weight: 800;
            font-size: 1.5rem;
            text-align: center;
        }
    }

    .events-listing {
        padding: 2rem 0;
        background: #fff;
    }

    .event {
        padding: 1rem;
        border: 1px solid #ccc;

        + .event {
            margin-top: 2rem;
        }

        &__name {
            display: block;
            margin-bottom: 1rem;
            color: #474f59;
            font-weight: 600;
            font-size: 1.3rem;
            line-height: 1;
        }

        &__body {
            display: flex;
            flex-flow: column;
        }

        &__overview {
            order: 2;

            p {
                + p {
                    margin-top: 1rem;
                }
            }
        }

        &__logo {
            display: none;
        }
    }

    .start {
        text-align: center;

        &__cta{
            margin: 2rem 0;
        }
    }

    @include m-respond-to('tablet') {
        .event {
            display: flex;
            align-items: center;

            &__logo {
                display: block;
                flex-shrink: 0;
                order: 2;
                flex-basis: 150px;
                margin: 0 0 0 2rem;
            }

            &__body {
                flex-flow: row wrap;
            }
        }
    }
}
