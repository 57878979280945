$link-color: $brand-primary;

.u-slideup-link {
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding: 0 5px;
    white-space: nowrap;
    color: $link-color;
    font-weight: 500;
    outline: none;
    vertical-align: bottom;
    text-decoration: none;
    cursor: pointer;
    transition: .5s;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba(52, 73, 94, .2);
        transform: translateY(95%);
        transition: transform 0.2s;
    }

    &:hover,
    &:focus {
        color: #1f9db8;
        text-decoration: none;

        &::before {
            transform: translateY(0);
        }
    }
}

.u-clickable {
    cursor: pointer;
}
