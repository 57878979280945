.gh-header {
    font-family: proxima-nova, sans-serif;
    font-weight: 500;
    border-bottom: solid 1px #E5E5E5;
    margin-left: -.5rem;

    &__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__menu {
        display: none;
    }

    &__logo {
        display: block;
        width: 160px;
    }

    &__actions {
        display: none;
    }

    .gh-button-redesign {
        max-height: 34px;
        padding: .5rem 1rem;
    }

    .gh-button-redesign__join {
        padding: .5rem 1rem;
    }

    .gh-route-link {
        margin-right: 1rem;
    }
}

@include m-respond-to('notebook') {
    .gh-header {
        .container {
            width: 66rem;
        }

        &__nav {
            height: 60px;
            padding: 0;
        }

        &__logo {
            flex-grow: 1;
            flex-shrink: 0;
            max-width: 150px;
        }

        &__menu {
            display: flex;
            justify-content: flex-end;
            flex-shrink: 0;

            .gh-list__link {
                padding: 0 .5rem;
                color: #343841;
                cursor: pointer;
                transition: .3s;
                font-size: 1rem;
                font-weight: 600;
                align-items: center;
                display: flex;
                line-height: 60px;

                .is-auth & {
                    padding: 0 1rem;
                }

                &:hover {
                    color: $brand-primary;
                }

                .icon {
                    vertical-align: middle;
                }

                &.join-button {
                    color: $brand-secondary;
                    background: #f3f3f3;
                    font-weight: 600;
                    font-size: 1rem;
                }
            }
        }

        &__actions {
            display: flex;
            flex-grow: 1;
            margin: 0 1rem;

            .gh-scout {
                flex-grow: 1;
                max-width: 160px;

                .el-input__inner {
                    border: 1px solid #e5e5e5;
                }

                .el-input__icon {
                    font-size: 1.5rem;
                }
            }
        }

        .gh-login {
            &__trigger {
                &.is-active {
                    color: $brand-primary;
                }
            }

            &__modal {
                background: #e5e5e5;

                > .container {
                    padding: 0;
                }
            }

            &__heading {
                margin: 2rem 0 1rem;
                font-size: 1.5rem;
            }
        }

        .gh-form {
            &__content {
                display: flex;
                padding-bottom: 2rem;
            }

            .el-form-item__content {
                display: flex;
                align-items: center;
            }

            .el-input__inner {
                background: #fff;
            }

            &__label {
                flex-basis: 110px;
                flex-shrink: 0;
                color: #808080;
            }

            &__group {
                flex-basis: 60%;
                margin-right: 2rem;
            }

            .el-form-item__error {
                left: 110px;
            }

            &__actions {
                align-self: flex-end;
                margin-bottom: 22px;

                .gh-button--cta {
                    height: 42px;
                    padding: 0 2rem;
                }
            }

            &__response {
                margin-bottom: 1rem;
            }
        }

        .cta {
            position: relative;
            display: flex;
            align-items: center;
            background: #e0e0e0;

            .gh-login__subheading {
                margin-bottom: 1rem;
                color: #565a60;
                font-weight: 800;
                font-size: 1.2rem;
            }

            .gh-button--cta {
                width: 200px;
            }

            .gh-login__close {
                position: absolute;
                top: 1rem;
                right: 1rem;
                color: $brand-secondary;
                cursor: pointer;
            }
        }
    }
}

@include m-respond-to('medium') {
    .gh-header {
        &__logo {
            max-width: 170px;
        }

        &__nav {
            padding: 0 20px;
        }

        &__actions {
            margin: 0 2rem;

            .gh-scout {
                max-width: 300px;
            }
        }
    }
}

@media only screen and (min-width: 75em) {
    .gh-header {
        .container {
            width: 76rem;
        }
    }
}
