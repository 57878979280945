.gh-page--home {
    .gh-hero {
        background: url('/images/app/home-banner.jpg') no-repeat left center;
        background-size: cover;

        .gh-button-redesign--primary {
            color: $pallete-dark-grey;
        }
        .gh-button-redesign + .gh-button-redesign {
            margin-left: .5rem;
        }

        &__cta {
            display: flex;
            flex-flow: column;
            .gh-button-redesign {
                + .gh-button-redesign {
                    margin-top: 1rem;
                    margin-left: 0;
                }
            }
        }
    }

    .gh-hero__container {
        position: relative;
        height: 440px;
    }

    .gh-hero__content {
        @include m-absolute();

        width: 80%;
        color: #085078;
    }

    .gh-hero__heading {
        margin-bottom: 1rem;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.1;
    }

    .gh-hero__subheading {
        margin-bottom: 2rem;
        font-size: 1.5rem;
        line-height: 1.2;
    }

    .intro {
        background: #fff;
        text-align: center;

        &__content {
            position: relative;
        }

        &__text {
            color: #085078;
            font-size: 1.2rem;
            line-height: 1.5;
        }

        &__quote {
            display: none;
        }
    }

    .gh-benefits {
        padding-top: 1rem;
        padding-bottom: 2rem;
        text-align: center;
        background: white;
        img {
            width: 80px;
            height: 80px;
        }
        .el-row {
            margin-bottom: -2rem;
        }
        .el-col {
            img {
                margin: auto;
            }
            margin-bottom: 2rem;

            .gh-benefit__title {
                font-size: 1.3rem;
                font-weight: 600;
                color: $pallete-dark-blue;
                padding-top: .5rem;
                padding-bottom: 1rem;
            }
            
            .gh-benefit__description {
                font-weight: 500;
                font-size: 1.23rem;
                line-height: 1.2;
            }
        }
    }

    .featured-programs {
        background: #F3F3F3;

        .gh-section__heading {
            text-align: left;
        }

        .gh-section__subheading {
            font-size: 1.6rem;
            font-weight: 500;
            color: #343841;
            margin-bottom: 2.3rem;
        }

        .programs-listing {
            margin-bottom: 2rem;
            padding-top: .5rem;

            .row {
                margin-left: -.4rem;
                margin-right: -1rem;
                > [class^=col] {
                    margin-bottom: 1.5rem;
                }
                .col-md-3 {
                    padding-left: .4rem;
                    padding-right: 1rem;
                }
            }

            .gh-card__image {
                background-size: cover;
            }
        }

        .more {
            text-align: center;
        }

        .el-tabs__item {
            font-family: proxima-nova, sans-serif;
            margin-bottom: .5rem;
            color: #85888D;
            font-weight: 400;
            font-size: 1.2rem;
        }

        .is-active {
            color: #303133;
        }

        .el-tabs__active-bar {
            height: 5px;
        }

        .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
            padding-left: unset;
        }

        &.featured-programs-crendetial {
            background-color: #E5E5E5;
        }
    }

    .gh-community {
        padding: 1rem 0;
        background: #DDDDDE;
        text-align: center;
        .container {
            padding-top: 0;
            padding-bottom: 0;
        }
        p {
            color: $pallete-dark-blue;
            font-size: 1.6rem;
            font-weight: 600;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }
        a {
            margin-left: .5rem;
            color: $brand-primary;
            font-weight: 600;
        }
        i {
            font-weight: 600;
            font-size: 1.4rem;  
        }
        span {
            margin-top: -0.2rem;
        }
    }

    .how-it-work {
        color: #575b61;
        background: #fff;

        .action {
            margin-top: 2rem;
            text-align: center;
        }

        .step-image {
            display: none;
        }

        .step {
            display: flex;

            + .step {
                margin-top: 2rem;
            }
        }

        .step-badge {
            margin-right: 20px;
        }

        .tag {
            display: block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            color: #fff;
            background: $brand-primary;
            font-size: 1.75em;
            line-height: 50px;
            text-align: center;
        }

        .step-details {
            color: $pallete-medium-grey;
            p {
                font-size: 1.2rem;
                line-height: 1.3;
            }
        }

        .title {
            margin-bottom: .5rem;
            font-weight: 700;
            font-size: 1.4rem;
            text-align: left;
        }
    }

    .way-to-learn {
        color: #474f59;
        background: #e5e5e5;
        text-align: center;

        .action {
            text-align: center;
        }

        .gh-blurb {
            margin-bottom: 2rem;

            &__icon {
                max-width: 60px;
                margin: 0 auto;
            }

            &__heading {
                margin-bottom: 1rem;
                color: #474f59;
                font-weight: 600;
                font-size: 1.3rem;
            }

            &__body {
                p {
                    font-size: 1.1rem;
                }
            }
        }
    }
    .gh-testimonials {
        padding-bottom: 3.5rem;
        .gh-section__heading {
            font-size: 2.56rem;
            margin-top: .5rem;
            margin-bottom: 1.3rem;
        }

        .gh-read-more-reviews-1 {
            display: block;
        }
        .gh-read-more-reviews-2 {
            display: none;
        }

        .gh-section__subheading {
            text-align: center;
            margin-bottom: .3rem;
            span {
                color: #51499D;
            }
        }
        
        .gh-rates-image {
            width: unset;
        }

        .el-row {
            max-width: 1324px;
            align-items: center;
            justify-content: center;
        }
        .gh-rates-left {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            span {
                font-size: 1.35rem;
                font-weight: 400;
                margin-right: 1rem;
            }

            .gh-button-redesign {
                padding: .5rem 1rem;
                border-radius: 3px;
                font-size: 1.2rem;
                font-weight: 400;
            }

            .gh-read-more-reviews {
                margin-top: 0;
            }
        }

        .gh-rates-right {
            display: flex;
            flex-flow: row;
            align-items: center;
            img {
                width: 249px;
                height: auto;
            }
        }
        .swiper-container {
            margin-top: 1.5rem;
            padding-bottom: 5rem;
        }

        .swiper-slide {
            height: auto !important;
        }

        .gh-rate-star {
            width: 20px;
        }

        .gh-read-more-reviews {
            margin-top: 2rem;
            text-align: center;
        }

        .gh-testimonial-sliders {
            .swiper-wrapper {
              height: 100%;
            }
      
            .swiper-button {
              margin-top: -4rem;
              width: 60px;
              height: 60px;
              img {
                width: 24px;
                height: 24px;
              }
            }
      
            .swiper-pagination-bullet-active {
              background-color: $pallete-dark-blue;
            }

            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;

                &:nth-child(2n) {
                    display: none;
                }
            }
          }

        .v-card {
            // padding: 1.5rem;
            border-radius: 4px;
            box-shadow: 0px 0px 5px 2px rgba(222,223,224,0.5);
            display: block;
            width: 100%;
            height: 100%;
            background: white;
            transition: box-shadow .6s cubic-bezier(.165,.84,.44,1);
            cursor: pointer;
            user-select: none;
            padding: 1.7rem 2rem 2rem 1.5rem;
            .v-title-text {
                color: $pallete-dark-grey;
                font-size: 1.5rem;
            }
            .v-content-text {
                color: $pallete-dark-grey;
                font-size: 1.1rem;
            }
            .v-author {
                color: $pallete-dark-grey;
                font-size: 1rem;
            }
            .v-readmore-text {
                color: $pallete-dark-grey;

                &::before {
                    width: 18px;
                    height: 18px;
                    flex-basis: 18px;
                }
            }
        }
    }


    .social-impact {
        background: #fff;

        .impact {
            &__content {
                text-align: center;
            }

            &__images {
                display: none;
            }

            &__text {
                font-size: 1.2rem;
                line-height: 1.3;
            }
        }

        .gh-button-redesign {
            margin-top: 2rem;
        }
    }

    @include m-respond-to('extra-small') {
        .gh-hero {
            &__cta {
                flex-flow: row;
                .gh-button-redesign {
                    + .gh-button-redesign {
                        margin-top: 0rem;
                        margin-left: .7rem;
                    }
                }
            }

            &__container {
                height: 360px;
            }

        }
    }

    @include m-respond-to('small') {
        .gh-community {
            p {
                flex-flow: row;
            }
        }

        .how-it-work {
            .action {
                text-align: left;
            }
        }
    }

    @include m-respond-to('tablet') {
        .gh-hero__content {
            left: 10px;
            width: 60%;
            transform: translate3d(0, -50%, 0);
        }
    }

    @include m-respond-to('notebook') {
        .gh-hero {
            background-position: center center;

            &__container {
                height: 550px;
            }

            &__heading {
                font-size: 2.5rem;
            }

            &__subheading {
                font-size: 1.8rem;
            }

            .gh-button--cta {
                margin-top: 1rem;
            }
        }

        .how-it-work {
            padding-bottom: 0;

            > .container {
                padding-bottom: 0;
            }

            .step-image {
                display: block;

                img {
                    height: 100%;
                }
            }

            .steps {
                padding-bottom: 1rem;

                .step-details,
                .title {
                    color: $pallete-light-grey;
                    transition: .5s;
                }

                .tag {
                    background-color: $pallete-light-grey;
                    transition: .5s;
                }

                .step {
                    cursor: pointer;

                    &.is-active {
                        .step-details {
                            color: $pallete-medium-grey;
                        }

                        .title {
                            color: $pallete-dark-grey;
                        }

                        .tag {
                            background-color: $brand-primary;
                        }
                    }
                }
            }

            .action {
                margin: 1rem 0 2rem;
                margin-left: 4.5rem;
            }
        }

        .intro {
            position: relative;

            &__text {
                font-size: 2rem;
            }

            &__quote {
                position: absolute;
                display: block;
                max-width: 50px;

                &--left {
                    top: -15px;
                    left: -10px;
                }

                &--right {
                    right: 210px;
                    bottom: -5px;
                }
            }
        }
        
        .social-impact {
            .gh-section__heading {
                text-align: left;
            }

            .gh-button-redesign {
                margin-top: 3rem;
            }

            .impact {
                &__content {
                    display: flex;
                    flex-flow: column;
                    align-items: flex-start;
                    justify-content: center;
                }
                
                &__text {
                    max-width: 406px;
                    text-align: left;
                }

                &__images {
                    display: block;

                    img {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
        .gh-testimonials {
            .swiper-container {
                padding-bottom: 33rem;
            }

            .gh-testimonial-sliders {
                height: 424px !important;

                .swiper-slide {
                    height: 424px !important;
                }
            }

            .v-card {
                position: relative;
                padding: 1.7rem 2rem 8rem 1.5rem;
               
                .v-card-footer {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    width: 100%;
                    padding: 0 2rem 2rem 1.5rem;
                    align-items: center;
                  }
            }
        }
    }

    @media only screen and (min-width: 900px) {
        .gh-testimonials {
            .swiper-container {
                padding-bottom: 31rem;
            }

            .gh-testimonial-sliders {
                height: 393px !important;

                .swiper-slide {
                    height: 393px !important;
                }
            }
        }
    }
    @include m-respond-to('notebook') {
        .gh-testimonials {
            .swiper-container {
                padding-bottom: 38rem;
            }

            .gh-testimonial-sliders {
                height: 503px !important;

                .swiper-slide {
                    height: 503px !important;
                }
            }
        }
        .gh-hero {
            background-position: center center;

            &__container {
                height: 550px;
            }

            &__heading {
                font-size: 2.5rem;
            }

            &__subheading {
                font-size: 1.8rem;
            }

            .gh-button--cta {
                margin-top: 1rem;
            }
        }

        .how-it-work {
            padding-bottom: 0;

            > .container {
                padding-bottom: 0;
            }

            .step-image {
                display: block;
                max-height: 415.8px;
                // max-width: 614px;
                img {
                    height: 100%;
                }
            }

            .steps {
                padding-bottom: 1rem;

                .step-details {
                    color: #AEAFB3;

                    .title {
                        transition: .5s;
                        font-weight: 700;
                    }
                    p {
                        transition: .5s;
                    }
                }

                .tag {
                    background: #AEAFB3;
                    transition: .5s;
                }

                .step {
                    cursor: pointer;

                    &.is-active {
                        .step-details {
                            color: $pallete-medium-grey;
                        }

                        .title {
                            color: $pallete-dark-grey;
                        }

                        .tag {
                            background-color: $brand-primary;
                        }
                    }
                }
            }

            .action {
                margin: 1rem 0 2rem;
                margin-left: 4.5rem;
            }
        }

        .intro {
            position: relative;

            &__text {
                font-size: 2rem;
            }

            &__quote {
                position: absolute;
                display: block;
                max-width: 50px;

                &--left {
                    top: -15px;
                    left: -10px;
                }

                &--right {
                    right: 210px;
                    bottom: -5px;
                }
            }
        }
    }

    @media only screen and (min-width: 1112px) {
        .gh-testimonials {
            .swiper-container {
                padding-bottom: 35rem;
            }

            .gh-testimonial-sliders {
                height: 450px !important;

                .swiper-slide {
                    height: 450px !important;
                }
            }
        }       
    }
    @media only screen and (min-width: 1366px) {
        .gh-testimonials {
            .swiper-container {
                padding-bottom: 32rem;
            }

            .gh-testimonial-sliders {
                height: 400px !important;

                .swiper-slide {
                    height: 400px !important;
                }
            }
        }
    }

    @media only screen and (min-width: 1600px) {
        .gh-testimonials {
            .swiper-container {
                padding-bottom: 34rem;
            }

            .gh-testimonial-sliders {
                height: 425px !important;

                .swiper-slide {
                    height: 425px !important;
                }

                .swiper-button-prev {
                    margin-left: 4rem;
                }

                .swiper-button-next {
                    margin-right: 4rem;
                }
            }
        }
    }
    @media only screen and (min-width: 1920px) {
        .gh-testimonials {
            .gh-testimonial-sliders {
                height: 431px !important;

                .swiper-slide {
                    height: 431px !important;
                }
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .gh-testimonials {
            .el-row {
                display: flex;
                flex-flow: column;
                .gh-rates-left {
                    text-align: center !important;
                }
                .gh-read-more-reviews-1 {
                    display: none;
                }
                .gh-read-more-reviews-2 {
                    display: block;
                    margin-top: 1rem;
                }

                .el-col {
                    display: flex;
                    justify-content: center;

                    + .el-col {
                        margin-top: 1rem;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 1200px) {
        .gh-hero__content {
            zoom: 109%;
        }
        .gh-section-join-community {
            .container {
                width: fit-content;
            }
            .gh-join-community-content {
                padding: 0;
            }
        }
    
    }

    @media only screen and (max-width: 767px) {
        .gh-hero {
            background: #E2E5EF;
            background-size: cover;
        }
    } 
}
