.gh-dashboard--contact {
    .gh-section__heading {
        font-weight: 400;
        text-align: left;
    }

    .gh-faqs__selector {
        margin-bottom: 2rem;
    }
    
    .el-textarea__inner {
        background: #fff;
    }

    @include m-respond-to('small') {
        .gh-form--contact {
            .gh-form__group {
                display: flex;
                justify-content: space-between;

                .el-form-item {
                    flex-basis: 49%;
                }
            }
        }
    }

    @include m-respond-to('tablet') {
        .gh-faqs__selector {
            margin: 20px 0 0;
        }
    }
}
