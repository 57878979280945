.gh-mega-menu {
    position: relative;

    &__trigger {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0;
        border: 1px solid #E5E5E5;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-right: 0;
        color: $brand-secondary;
        background: #F5F5F5;
        font-weight: 700;
        font-size: 1rem;
        transition: all .5s;
        cursor: pointer;
        min-width: 111px;
        justify-content: center;

        &.is-active,
        &:hover {
            border-bottom-left-radius: 0;
            border-bottom: 1px solid #F5F5F5;
            color: $brand-secondary;
        }

        i {
            margin-left: 10px;
            color: $brand-secondary;
        }
    }

    &__modal {
        position: absolute;
        top: 100%;
        z-index: get-value-of($z-index, 'mega-menu');
        display: flex;
        width: 770px;
        border: 1px solid #E5E5E5;
        border-radius: 2px;
        border-top-left-radius: 0;
        background: #fff;
    }

    &__catalogues {
        flex-basis: 160px;
        flex-shrink: 0;
        background: #F5F5F5;

        .gh-button-redesign {
            color: #484EA3;
            border-color: #484EA3;
            display: flex;
            justify-content: center;
            max-width: 140px;
            align-items: center;
            margin: auto;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
        }

        .gh-list {
            flex-flow: row wrap;
            justify-content: flex-start;
            margin-bottom: 1rem;

            &__item {
                flex-basis: 100%;
                padding: .7rem 1rem;
                color: #2c3138;
                background: #f7f7f7;
                transition: .3s;
                cursor: pointer;

                &.is-active {
                    background: white;
                }
            }
        }
    }

    &__items {
        flex-grow: 1;
        padding: 1rem;

        &__title {
            padding-left: 10px;
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }

        .gh-list {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 1rem;
        }

        .view-all {
            margin-top: 1rem;
            text-align: right;
        }
    }

    &__chunk {
        flex-basis: 33%;
        padding: 0 10px;

        .gh-list__item {
            + .gh-list__item {
                margin-top: 10px;
            }
        }

        .gh-list__link {
            display: block;
            color: #5D6067;
            font-weight: 500;
            line-height: 1.3;
            cursor: pointer;

            &:hover {
                color: $brand-primary;
            }
        }
    }
}
