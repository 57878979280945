.gh-page-maintenance {
    display: flex;
    justify-content: center;
    align-content: center;
    background: url('/images/app/maintenance_mobile.jpg') no-repeat center center;
    background-size: cover;
    height: 100vh;
    width: 100%;

    @include m-respond-to('tablet') {
        background-image: url('/images/app/maintenance_tablet.jpg');
    }

    @include m-respond-to('medium') {
        background-image: url('/images/app/maintenance.jpg');
    }
}

.gh-maintenance-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    padding: 1rem;
    background: #fff;
    border-radius: 4px;
    transform: translate3d(-50%, -50%, 0);

    p {
        font-size: 20px;

        + p {
            margin-top: .5rem;
        }
    }

    @include m-respond-to('tablet') {
        max-width: 768px;
        padding: 2rem;
    }
}

.gh-maintenance-heading {
    margin-bottom: 1rem;
    color: #085078;
    font-size: 2.7rem;
    font-weight: 500;
}