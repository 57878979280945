.gh-page {
    height: auto;
    background: #f3f3f3;

    &.is-loading {
        height: 100vh;
    }

    &__tagline {
        color: #95989c;
        font-size: 1.1rem;
        line-height: 1.5;
        text-align: center;
    }

    &__heading {
        margin-bottom: 1rem;
        color: #474f59;
        font-weight: 300;
        font-size: 3rem;
        text-align: center;
    }

    .gh-page__sidebar {
        margin-bottom: 2rem;
    }

    @include m-respond-to('notebook') {
        .gh-page__sidebar {
            margin-bottom: 0;
        }
    }
}

