.gh-tags {
    &__heading {
        margin: 0 0 .5rem;
        font-weight: 700;
        font-size: 1rem;
    }
}

.gh-tag {
    height: auto;
    margin: 0 .5rem .5rem 0;
    padding: 5px;
    border-radius: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.5;
    color: #fff;

    .el-icon-close {
        color: inherit;
    }
}
