//==================================================
//                 [-- FORM --]
//==================================================
.el-input {
    font-size: 1rem;
}

.el-select,
.el-input {
    width: 100%;
}

.el-input__inner {
    height: 42px;
    padding: .75rem;
    border-radius: 0;
    background: #e5e5e5;
    font-weight: 500;
    font-family: inherit;

    &:focus {
        border-color: $brand-secondary;
    }

    &::placeholder {
        color: #607d8b;
        font-weight: 400;
    }
}

.el-textarea__inner {
    font-size: 1rem;
    font-family: inherit;
}

.el-checkbox,
.el-checkbox__input {
    white-space: normal;
}

.el-checkbox__label {
    font-weight: 600;
    font-size: 1rem;
}

.el-radio {
    display: flex;
    margin: 0;
    white-space: inherit;
}

.el-radio__inner {
    display: block;
}

.el-radio__label {
    font-size: 1rem;
    line-height: 1.5;
}

.el-select {
    .el-input__inner {
        &:focus {
            border-color: $brand-secondary;
        }
    }
}

.el-select-dropdown {
    font-weight: 500;
}

.el-select-dropdown__item {
    &.selected {
        color: #fff;
        background: $brand-primary;

        &.hover {
            background: $brand-primary;
        }
    }

    &.hover {
        color: #fff;
        background: $brand-primary;
    }
}

//==================================================
//                 [-- ALERT --]
//==================================================
.el-alert {
    .el-alert__description {
        font-size: 1rem;
    }
}

.el-alert__title {
    font-size: 1.2rem;

    &.is-bold {
        font-weight: 600;
    }
}

//==================================================
//                 [-- TABS --]
//==================================================
.el-tabs__item {
    font-weight: 600;
    font-size: 1.1rem;

    &.is-active {
        color: $pallete-dark-grey;
    }
}

.el-tabs__nav-wrap::after {
    background: #ccc;
}

.el-tabs__active-bar {
    background-color: $pallete-dark-grey;
}

.el-dropdown {
    cursor: pointer;
}

.el-popover {
    word-break: break-word;
    text-align: left;

    a {
        font-family: inherit;
    }
}

.el-tooltip__popper {
    max-width: 200px;
    font-size: 14px;
    text-align: center;
}

.el-checkbox__label {
    font-weight: 600;
    font-size: 1rem;
}

.el-checkbox__input.is-checked + .el-checkbox__label,
.el-radio__input.is-checked + .el-radio__label {
    color: inherit;
}

.el-loading-spinner {
    .el-loading-text {
        color: $brand-secondary;
        font-size: 1.2rem;
        animation: loading-text-color 2s infinite;
    }
}

//==================================================
//                 [-- Loading --]
//==================================================
.el-loading-mask {
    background: rgba(255, 255, 255, .8);
}

.el-loading-spinner .path {
    stroke-width: 4;
    stroke: $brand-primary;
}

.el-loading-mask.is-fullscreen .el-loading-spinner .circular {
    width: 80px;
    height: 80px;
}

@keyframes loading-text-color {
    0%, 100% {
        color: $brand-secondary;
    }

    50% {
        color: $brand-primary;
    }
}

//==================================================
//                 [-- MENU --]
//==================================================
.el-menu {
    border-radius: 0;
}

//==================================================
//                 [-- Message Box --]
//==================================================
.el-message-box {
    border-radius: 0;

    .el-button {
        border-radius: 0;
        font-size: 1rem;
        font-family: inherit;

        &--primary {
            background: $brand-primary;
        }
    }
}

.el-message-box__title {
    font-weight: 600;
}

//==================================================
//                 [-- PAGINATION --]
//==================================================
.el-pager {
    li {
        &.active {
            border: $brand-secondary;
            background: $brand-secondary;
        }
    }
}

//==================================================
//                 [-- COLLAPSE --]
//==================================================
.el-collapse-item__arrow {
    display: none;
}

//==================================================
//                 [-- DROPDOWN --]
//==================================================
.el-dropdown-menu__item {
    font-size: 1rem;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    color: $brand-secondary;
}

//==================================================
//                 [-- AUTOCOMPLETE --]
//==================================================
.el-autocomplete-suggestion__wrap {
    padding: 0;
}

.el-autocomplete-suggestion li {
    padding: 0;
    white-space: normal;
    font-size: 1rem;
}
