//==================================================
//                 [-- BRANDS --]
//==================================================
$brand-primary: #24c6dc;
$brand-secondary: #514a9d;
$pallete-yellow: #ffdb77;
$pallete-dark-grey: #343841;
$pallete-medium-grey: #575b61;
$pallete-light-grey: #c3c8d2;
$pallete-lightest-grey: #f6f6f6;
$pallete-dark-blue: #085078;

//==================================================
//                 [-- COMMON SETTINGS --]
//==================================================
$settings: (
    'border-radius': 0
);

$z-index: (
    'back-to-top-button': 5,
    'mega-menu': 10,
    'main-navigation': 100,
    'modal': 300,
    'fullscreen-spinner': 10000,
    'filters-modal': 50000,
);

//==================================================
//                 [-- TYPOGRAPHY --]
//==================================================
$family-primary: 'Catamaran', sans-serif;
$text-color: #2c3138;

//==================================================
//                 [-- BREAKPOINTS --] Breakpoints
//==================================================
$breakpoints: (
    'extra-small'       : 480px,
    'small'             : 600px,
    'tablet'            : 768px,
    'notebook'          : 1024px,
    'medium'            : 1280px,
    'large'             : 1680px,
);

//==================================================
//                 [-- VENDOR VARIABLES --]
//==================================================
// Hamburger
$hamburger-layer-color: $brand-secondary;
$hamburger-padding-x: 10px;
$hamburger-padding-y: 10px;
$hamburger-layer-width: 35px;
$hamburger-layer-spacing: 4px;
