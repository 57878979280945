.gh-filters {
    position: relative;
    z-index: get-value-of($z-index, 'back-to-top-button');

    &__trigger {
        display: block;
        width: 100%;
        max-width: 340px;
        margin: 0 auto;
        font-weight: 600;
        font-size: 1.2rem;
        text-align: center;
    }
    
    &__wrapper {
        color: $pallete-dark-grey;
        background: #fff;

        &.is-mobile {
            display: none;

            .gh-button--cta {
                margin-bottom: 1rem;
            }

            .gh-filters__container {
                @include m-absolute();

                position: relative;
                overflow-x: hidden;
                overflow-y: auto;
                width: 100%;
                max-width: 320px;
                height: 90%;
                max-height: 650px;
            }
        }

        &.is-visible {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: get-value-of($z-index, 'filters-modal');
            display: block;
            width: 100%;
            background: rgba(0, 0, 0, .8);
            //animation: puff-in-center .3s ease-in both;
        }
    }

    &__container {
        background: #fff;
    }

    &__close {
        @include m-absolute('y', 'right', 1rem);

        top: 1.5rem;
        font-size: 1.5rem;
    }

    &__header {
        .gh-filters__heading {
            padding: 1rem;
            border-bottom: 1px solid #ccc;
        }
    }

    &__body {
        padding: 1rem 0;
    }

    &__group {
        border-bottom: 1px solid #ccc;

        + .gh-filters__group {
            margin-top: 1rem;
        }
    }

    &__heading {
        margin-bottom: 1rem;
        padding: 0 1rem;
        font-weight: 700;
        font-size: 1.3em;
    }

    &__list {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 200px;
        padding: 0 1rem 1rem;
    }

    .gh-filter {
        display: flex;
        align-items: flex-start;
        white-space: normal;
        font-size: 1rem;

        + .gh-filter {
            margin-top: 1rem;
            margin-left: 0;
        }

        .el-checkbox__label {
            padding-left: 5px;
            font-weight: 500;
        }

        .el-checkbox__input.is-checked + .el-checkbox__label {
            color: inherit;
        }
    }

    &__footer {
        padding: 1rem;
        text-align: center;

        .gh-button--primary {
            background: transparent;
        }
    }

    //.gh-filters__dialog {
    //    width: 80%;
    //}

    @include m-respond-to('extra-small') {
        &__wrapper {
            &.is-mobile {
                .gh-button--cta {
                    margin-bottom: 0;
                    border: 1px solid $brand-primary;
                }

                .gh-filters__container {
                    max-width: 400px;
                }
            }
        }
    }

    @include m-respond-to('notebook') {
        &__list {
            max-height: none;
        }

        &__footer {
            .gh-button--primary {
                display: block;
                width: 100%;
            }
        }
    }
}
