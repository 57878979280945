.gh-page--affiliate {
    .gh-hero {
        background: url('/images/promo/promo_banner.jpg') no-repeat left center;
        background-size: cover;
    }

    .gh-hero__container {
        position: relative;
        height: 360px;
    }

    .gh-hero__content {
        @include m-absolute();

        width: 80%;
        padding: 1rem;
        color: #085078;
        background: rgba(255, 255, 255, 0.8);
    }

    .gh-hero__heading {
        margin-bottom: 1rem;
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.2;
    }

    .gh-hero__subheading {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 1.2;
    }

    .gh-section {
        color: #555a60;

        &__heading {
            margin-bottom: 1rem;
        }

        &__subheading {
            margin-bottom: 1rem;
            color: #2a3038;
            font-size: 1.8rem;
            text-align: center;
        }
    }

    .has-gradient {
        @extend %gradient-venice-blue;
    }

    .cta {
        text-align: center;

        .gh-section__heading,
        .gh-section__subheading,
        .gh-button {
            color: #fff;
        }

        .gh-button {
            border: 1px solid #fff;
            background: transparent;
        }
    }

    .what-you-get {
        background: #fff;

        .listing {
            &__item {
                text-align: center;

                + .listing__item {
                    margin-top: 2rem;
                }
            }
        }

        .item {
            &__icon {
                max-width: 70px;
                margin: 0 auto;
            }

            &__content {
                p {
                    font-weight: 700;
                    font-size: 1rem;
                    line-height: 1.3;
                }
            }
        }
    }

    .intro {
        padding-bottom: 2rem;

        &__text {
            margin: 0 auto;
            color: #2a3038;
            font-weight: 600;
            font-size: 1.5rem;
            text-align: center;
        }
    }

    .mission {
        background: #fff;

        &__content {
            margin: 0 auto;
            text-align: center;
        }

        &__text {
            color: #2a3038;
            font-size: 1.4rem;
            text-align: center;

            .quoted {
                position: relative;
                display: inline-block;
            }
        }

        &__quote {
            position: absolute;
            display: none;
            width: 50px;

            &--left {
                top: -50px;
                left: -50px;
            }

            &--right {
                top: 5px;
                right: -60px;
            }
        }
    }

    .control {
        color: #555a60;
        background: #f4f5f6;

        &__image {
            display: none;
        }

        &__accordion {
            margin-top: 2rem;
        }

        .el-collapse {
            border: 0;

            &-item {
                &.is-active {
                    .accordion__icon {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .el-collapse-item__header {
            display: flex;
            align-items: center;
            height: auto;
            padding: 1rem;
            border: 0;
            background: transparent;
            line-height: 1;
        }

        .el-collapse-item__wrap {
            border-bottom: 0;
            background: transparent;
        }

        .el-collapse-item__header__arrow {
            display: none;
        }

        .el-collapse-item__content {
            font-size: 1rem;
        }

        .accordion {
            &__icon {
                max-width: 30px;
                transition: transform .2s;
            }

            &__heading {
                flex-grow: 1;
                margin-left: 1rem;
                font-weight: 700;
                font-size: 1.2rem;
            }
        }
    }

    .cta-alt {
        color: #fff;
        background: #3e4557;
        text-align: center;

        .cta__icon {
            display: none;
        }

        .cta__text {
            font-size: 2rem;
        }

        .gh-button {
            margin-top: 2rem;
        }
    }

    .learning-modes {
        color: #555a60;
        background: #e5e5e5;

        &__listing {
            margin-top: 2rem;
        }

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }

        .lm {
            text-align: center;

            + .lm {
                margin-top: 2rem;
            }

            &__body {
                text-align: left;
            }

            &__icon {
                max-width: 80px;
                margin: 0 auto;
            }

            &__heading {
                margin-bottom: .5rem;
                font-weight: 700;
                font-size: 1.5rem;
            }
        }
    }

    .experts {
        color: #555a60;
        background: #fff;

        &__content {
            margin-top: 2rem;
        }

        &__heading {
            margin-bottom: .5rem;
            font-weight: 600;
            font-size: 1.3rem;
            line-height: 1;
        }

        [class^='col-'] {
            margin-bottom: 1rem;
        }

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }
    }

    .impact {
        background: #f4f5f6;

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }

        &__content {
            margin-top: 1rem;

            p {
                + p {
                    margin-top: .5rem;
                }
            }
        }
    }

    .offers {
        .gh-section__heading,
        .gh-section__subheading {
            color: #fff;
        }

        .text-highlight {
            color: #fff;
            font-size: 1.5rem;
            line-height: 1.2;
        }

        .divider {
            margin: 1rem 0;
            border-top: 2px solid #fff;
        }

        &__listing {
            margin-top: 5rem;
        }
        
        .offer {
            margin-top: 2rem;

            .offer__heading {
                margin: 1rem 0;
                font-size: 1.6rem;
                font-weight: 400;
                color: #514a9d;
            }

            &__content {
                position: relative;
                margin-top: .5rem;
                padding: 1rem;
                background: #fff;
                box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
            }

            &__save {
                position: absolute;
                top: -50px;
                right: 0;
                max-width: 110px;
            }

            &__heading {
                max-width: 60%;
                color: #fff;
                font-size: 1.2rem;
            }

            ul {
                padding-left: 20px;
                list-style: disc;
            }

            &__investment {
                margin-top: 1rem;
                color: $brand-secondary;

                span {
                    margin-right: .5rem;
                    font-weight: 600;
                }

                p {
                    font-size: 1.5rem;
                }
            }

            &__action {
                margin-top: 2rem;
                color: #fff;
                text-align: center;

                .gh-button {
                    margin-bottom: 1rem;
                    color: #fff;
                    background: #2c3138;
                }

                p {
                    font-size: .8rem;
                }

                a {
                    color: #fff;
                    font-weight: 600;
                }
            }
        }

        .offer-conditions {
            p {
                + p {
                    margin-top: 1rem;
                }
            }
        }
    }

    .reasons {
        background: #fff;
        text-align: center;

        &__stats {
            margin-top: 2rem;
        }

        .stat {
            margin-bottom: 1rem;
            color: $brand-primary;

            &__number {
                font-weight: 700;
                font-size: 3rem;
            }

            &__text {
                font-weight: 600;
                font-size: 1.2rem;
            }
        }

        &__details {
            .heading {
                font-weight: 700;
                font-size: 1.2rem;
            }
        }

        .details {
            margin: 2rem 0;
        }

        .detail {
            margin-bottom: 1rem;
            text-align: center;

            &__icon {
                max-width: 80px;
                margin: 0 auto;
            }

            &__content {
                margin-top: .5rem;
            }

            &__heading {
                font-weight: 700;
                font-size: 1.2rem;
            }
        }
    }

    .testimonials {
        background: #e5e5e5;

        .gh-section__subheading {
            margin-bottom: 2rem;
        }

        .testimonial {
            width: 100%;

            &__content {
                padding: 1rem;
                border-bottom: 5px solid;
                background: #fff;
            }
        }

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }
    }

    .start {
        text-align: center;

        &__cta{
            margin: 2rem 0;
        }
    }
    
    .gh-page--registration {
        margin-top: 2rem;
        text-align: left;
    }

    @include m-respond-to('extra-small') {
        .what-you-get {
            .listing {
                display: flex;
                justify-content: space-between;
                flex-flow: row wrap;

                &__item {
                    flex-basis: 50%;
                    max-width: 50%;
                    padding: 1rem;

                    + .listing__item {
                        margin-top: 0;
                    }
                }
            }
        }

        .offers {
            .offer {
                + .offer {
                    margin-top: 5rem;
                }

                &__heading {
                    max-width: 100%;
                }
            }
        }
    }

    @include m-respond-to('small') {
        .offers {
            .offer {
                ul {
                    max-width: 70%;
                }
            }
        }
    }

    @include m-respond-to('tablet') {
        .learning-modes {
            &__listing {
                display: flex;
                flex-flow: row wrap;
            }

            .lm {
                flex-basis: 50%;
                max-width: 50%;
                margin-bottom: 1rem;
                padding: 1rem;

                + .lm {
                    margin-top: 0;
                }
            }
        }

        .reasons__stats {
            display: flex;
            flex-flow: row wrap;
        }

        .stat {
            flex-basis: 33.33%;
            max-width: 33.33%;
            padding: 1rem;
        }

        .testimonials {
            .testimonial {
                max-width: calc(100% / 3 - 16px * 2);
            }
        }

        .offers {
            .offer {
                &__save {
                    top: -65px;
                    right: calc(50% - 55px);
                }
            }
        }
    }

    @include m-respond-to('notebook') {
        .mission {
            &__quote {
                display: block;
            }
        }

        .control {
            overflow: hidden;

            .gh-section__subheading {
                text-align: left;
            }

            &__container {
                position: relative;
                display: flex;
            }

            &__content {
                flex-basis: 60%;
                max-width: 60%;
                z-index: 1;
            }

            &__image {
                position: absolute;
                top: -50px;
                left: 50%;
                display: flex;
                width: 580px;
                height: 580px;
                border-radius: 50%;
                background: #f9f9fa;

                img {
                    max-width: 300px;
                    margin: 0 auto;
                }
            }

            .el-collapse-item__header {
                padding: .5rem 0;
            }

            .el-collapse-item__wrap {
                padding-left: 2rem;
            }
        }

        .learning-modes {
            .gh-section__subheading {
                text-align: left;
            }

            .lm {
                display: flex;
                align-items: flex-start;
                margin-bottom: 2rem;
                padding: 0;

                &__icon {
                    flex-basis: 70px;
                    max-width: 70px;
                    flex-shrink: 0;
                }

                &__content {
                    padding: 0 1rem;
                    text-align: left;
                }
            }

            &__cta {
                margin-top: 0;
            }
        }

        .experts {
            .gh-section__subheading {
                text-align: left;
            }

            .col-md-6 {
                padding-right: 2rem;
            }
        }

        .impact {
            padding: 0;
            background: radial-gradient(circle at right, transparent 40%, rgba(244, 246, 246, 1) 50%), url('/images/app/full-social-impact-image.jpg');
            background-position: center center, right center;
            background-size: contain, 700px;
            background-repeat: no-repeat;

            &__wrapper {
                max-width: 50%;
                padding: 2rem 0;
            }

            .gh-section__subheading,
            &__cta {
                text-align: left;
            }
        }

        .reasons {
            &__stats {
                margin-top: 1rem;
            }

            .stat {
                margin-bottom: 2rem;

                &__number {
                    font-size: 5rem;
                }
            }

            .heading {
                max-width: 60%;
                margin: 0 auto;
            }

            .detail {
                display: flex;
                align-items: flex-start;
                margin-bottom: 2rem;

                &__icon {
                    flex-basis: 70px;
                    max-width: 70px;
                    flex-shrink: 0;
                    margin: 0
                }

                &__content {
                    margin: 0;
                    padding: 0 1rem;
                    text-align: left;
                }
            }
        }

        .gh-hero {
            &__content {
                width: 60%;
                background: transparent;
                text-align: center;
            }
        }

        .cta,
        .cta-alt {
            > .container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .left {
                .gh-section__heading {
                    text-align: left;
                }

                .gh-section__subheading,
                .gh-section__heading {
                    margin-bottom: 0;
                }
            }

            .right {
                .gh-button {
                    margin-top: 0;
                }
            }
        }

        .what-you-get {
            .listing__item {
                flex-basis: 25%;
                max-width: 25%;
            }
        }

        .offers {
            .gh-section__heading,
            .text-highlight,
            .gh-section__subheading {
                text-align: center;
            }

            &__listing {
                display: flex;
                justify-content: space-between;
            }

            .offer {
                flex-grow: 1;
                flex-basis: 0;
                max-width: 100%;
                margin-top: 0;
                padding: 1rem;

                .offer__heading {
                    margin-top: 0;
                }

                + .offer {
                    margin-top: 0;
                }

                &__content {
                    padding: 2rem;
                }

                &__highlight {
                    max-width: 50%;
                    margin-top: 0;
                }
            }
        }
    }

    @include m-respond-to('medium') {
        .gh-hero {
            &__container {
                height: 500px;
            }

            &__heading {
                font-size: 2.8rem;
            }

            &__subheading {
                font-size: 2.2rem;
            }
        }

        .impact {
            padding: 0;
            background: radial-gradient(circle at right, transparent 25%, #f4f6f6 35%), url("/images/app/full-social-impact-image.jpg");
            background-position: center center, right center;
            background-size: contain, 700px;
            background-repeat: no-repeat;
        }

        .offers {
            &__listing {
                position: relative;
                margin: 1rem auto 0;
            }

            .offer {
                &__save {
                    top: 10px;
                    right: calc(50% - 55px);
                }

                ul {
                    max-width: 90%;
                }
            }
        }
    }
}