.gh-dashboard--results {
    .gh-tag {
        margin: 0;

        &.gh-tag--text {
            background: #085078;
        }

        &.gh-tag--video {
            background: $brand-secondary;
        }
    }

    .print-transcript {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2rem;

        .icon {
            display: block;
            width: 30px;
            cursor: pointer;
        }
    }

    .el-table {
        td,
        th {
            padding: 1rem 0;
            font-weight: 600;
        }

        td.el-table__expanded-cell {
            padding: 1rem;
            background: #ccc;

            p {
                + p {
                    margin-top: .5rem;
                }
            }
        }
    }

    .gh-member-results {
        .course__title {
            word-break: normal;
        }

        .course__status {
            &.is-completed {
                color: #13ce66;
            }

            &.is-failed {
                color: #ff4949;
            }
        }

        .course__download {
            display: block;

            .icon {
                display: block;
                max-width: 40px;
                margin: 0 auto;
                cursor: pointer;
            }
        }
    }

    @include m-respond-to('tablet') {
        .controls {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;
        }

        .gh-fuzzy-search {
            margin-bottom: 0;
        }

        .print-transcript {
            margin: 0 0 0 5rem;
        }
    }
}
