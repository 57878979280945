.gh-page--mcd {
    p {
        color: #333;
        font-weight: 500;
    }

    .gh-section__heading {
        color: $brand-secondary;
        font-size: 40px;
        text-align: left;
    }

    @include m-respond-to('notebook') {
        .gh-section__heading {
            font-size: 2.5rem;
        }

        p {
            font-size: 18px;
        }
    }
}