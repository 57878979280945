.gh-page--team {
    .controls {
        margin-bottom: 2rem;
    }

    .gh-search-section {
        padding-bottom: 0;
        margin-top: -20px;
        .container {
            padding-bottom: 0;
        }
        .gh-fuzzy-search {
            max-width: 480px;
        }
    
        .gh-search {
            display: flex;
            justify-content: space-between;
    
            .gh-fuzzy-search {
                max-width: 390px;
                input {
                    height: 2.2rem;
                    background: white;
                    border-radius: 2px;
                    border: none;
                }
                ::placeholder {
                    color: #AEAFB3;
                }
                .el-input__icon {
                    line-height: 34px;
                    color: #818286;
                }
            }
        }
    
        .gh-sort {
            font-family: proxima-nova, sans-serif;
            margin-top: .5rem;
            margin-left: .5rem;
            font-size: 1rem;
            .el-dropdown-link {
                color: #111826;
                display: flex;
                flex-flow: row nowrap;
                img {
                    width: 16px;
                }
            }
        }
    }

    .listing {
        margin: -.5rem;
        &__placeholder {
            height: 100vh;
        }

        &__container {
            display: flex;
            flex-flow: row wrap;
        }

        &__item {
            padding: .5rem;
            flex-grow: 0;
            max-width: 50%;
            flex-basis: 50%;
        }
    }

    .actions {
        margin-top: 3rem;
        margin-bottom: 3rem;
        text-align: center;
        button {
            padding: 1rem 3rem;
        }
    }

    .author__name {
        margin-bottom: .5rem;
        color: $pallete-dark-blue;
        font-weight: 600;
        font-size: 1.2rem;
    }

    .author__works {
        color: $pallete-dark-blue;
        font-size: .95rem;
    }    

    .gh-card {
        box-shadow: 0px 0px 5px 2px rgba(222,223,224,0.5);
        border: solid 0 white;
        border-radius: 2px;
        &__footer {
            padding: 0 1rem 1rem;
        }
        &__image {
            background-size: cover;
            width: 100%;
            height: 168px;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;

        }
    }

    @include m-respond-to('extra-small') {
        .gh-card {
            &__image {
                height: 200px;
            }
        }
    }

    @include m-respond-to('tablet') {
        .gh-card {
            &__image {
                width: 100%;
                height: 254px;
            }
        }
        .listing {
            &__item {
                flex-basis: 33%;
            }
        }
    }

    @include m-respond-to('notebook') {
        .gh-body {
            .container {
                padding: 20px 40px;
            }
        }

        .listing {
            &__item {
                max-width: 25%;
                flex-basis: 25%;
            }
        }

        .gh-card {
            transition: box-shadow .3s, transform .3s;

            &:hover {
                transform: translate3d(0, -5px, 0);
                box-shadow: 0 0 20px rgba(0, 0, 0, .25);
            }
        }
    }
}