.gh-tnc {
    display: inline-block;

    &__trigger {
        padding: 0;
        color: $brand-primary;
        font-weight: inherit;
    }

    &__modal {
        width: 80%;
        max-width: 600px;
    }

    &__body {
        font-weight: 500;
        font-size: 1rem;
    }
}
