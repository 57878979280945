.gh-stripe {
    background: #e5e5e5;

    &__element {
        height: 42px;
        padding: .75rem;
        border: 1px solid #bfcbd9;
        background: inherit;
    }

    &__error {
        padding-top: 4px;
        color: #ff4949;
        font-size: 14px;
        line-height: 1;
    }

    &__label {
        max-width: 350px;
        height: 42px;
        padding: .75rem;
        border: 1px solid #bfcbd9;
        background: #e5e5e5;
        line-height: 1;
    }
}
