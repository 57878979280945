.gh-message-box {
    .el-message-box__content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .el-message-box__status {
        position: relative;
        transform: translate3d(0, 0, 0);
    }

    .el-message-box__message {
        flex-grow: 1;
        margin-left: 0 !important;
        padding-left: 1rem;
    }
}