@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="text"],
    input[type="url"],
    select:focus,
    textarea {
        font-size: inherit;
    }
}