.mcd-header {
    .is-sticky {
        z-index: 2;
        width: 100% !important;
    }

    .header-container {
        height: 71px;

        &.is-sticky {
            background: rgba(255, 255, 255, 0.9);
        }
    }

    .gh-header__menu {
        align-items: center;

        .tagline {
            margin-right: 2rem;
            font-weight: 500;
            font-style: italic;
            font-size: 18px;
        }

        .join-button {
            color: #fff;
            background: $brand-primary;
            font-size: 20px;
        }

        .register-button {
            color: $brand-secondary;
            background: #f3f3f3;
            font-size: 20px;
        }
    }
}

.mcd-navigator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: rgba(0, 0, 0, .85);

    &.is-sticky {
        z-index: 2;
        width: 100% !important;
    }

    .gh-list {
        display: flex;

        &__item {
            padding: 1rem 2rem;
            font-weight: 600;
            font-size: 16px;
            cursor: pointer;
            transition: color .3s;

            &:hover {
                color: $brand-primary;
            }
        }
    }
}