.gh-page--help {
    .sidebar {
        margin-bottom: 2rem;
    }

    .gh-page__body {
        margin-top: -20px;
    }

    .gh-page__sidebar {
        box-shadow: 0px 0px 5px 2px rgba(222, 223, 224, 0.5);
        border-radius: 2px;
    }

    .gh-filters__heading {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: .5rem;
    }

    .gh-filters {
        .gh-button-redesign {
            margin-bottom: .5rem;
            color: #13517B;
            border-color: #13517B;
            padding: .6rem 1.5rem;
            width: 100%;
            max-width: 156px;
        }
    }
    .gh-filter {
        margin-top: .5rem;
        .el-checkbox__label {
            padding-left: 1rem;
            font-weight: 500;
            font-size: 1rem;
            font-family: proxima-nova, sans-serif;
        }
    }

    .useful-links {
        display: none;

        .heading {
            font-size: 1.5rem;
            color: #343841;
            margin-bottom: 1rem;
        }
    }

    .gh-scout {
        margin-bottom: 2rem;
    }

    .gh-tags {
        margin-bottom: 1rem;
        .gh-tag {
            background: #409EFF;
            border: 1px solid rgba(64,158,255,.2);
        }
    }

    .gh-newsletter {
        margin-top: 2rem;
    }

    .gh-filters__list {
        max-height: inherit;
    }

    .el-collapse-item__header p {
        font-size: 1.1rem;
        font-family: proxima-nova, sans-serif;
    }

    .el-collapse-item__content p {
        font-size: 1.1rem;
        font-family: proxima-nova, sans-serif;
    }

    @include m-respond-to('notebook') {
        .gh-filters__footer {
            text-align: left;
        }

        .gh-filters {
            .gh-button-redesign {
                padding: 1rem 1.5rem;
            }

            &__body {
                padding-bottom: 0;
            }

            &__group {
                border-bottom: 0;
            }

            &__footer {
                padding-bottom: 2rem;
            }
        }

        .useful-links {
            display: block;
            padding: 2rem 1rem 1rem;
            border-top: 1px solid #ccc;
            background: #fff;

            .heading {
                font-weight: 700;
                font-size: 1.5rem;
            }

            .gh-list {
                margin: .5rem 0;

                &__link {
                    display: block;
                    color: #575b61;
                    font-weight: 600;
                    transition: .3s;

                    &:hover {
                        color: $brand-primary;
                    }
                }
            }

            .email-link {
                font-weight: 600;

                a {
                    color: $brand-primary;
                }
            }
        }

        .gh-page__main {
            padding-left: 1rem;
        }
    }
}