.presenters {
    .gh-card {
        display: flex;

        &__header {
            flex-basis: 40%;
            flex-shrink: 0;
        }

        &__image {
            height: 100px;
        }
    }

    .presenter {
        + .presenter {
            margin-top: 2rem;

        }

        &__modal {
            width: 80%;
        }

        &__name {
            font-size: 1.3rem;
            line-height: 1.2;
        }

        &__bio {
            font-size: 1rem;
        }
    }

    @include m-respond-to('extra-small') {
        .gh-card {
            &__image {
                height: 150px;
            }
        }
    }

    @include m-respond-to('tablet') {
        .presenters__listing {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }

        .presenter {
            + .presenter {
                margin-top: 0;
            }

            flex-basis: 50%;
            margin-bottom: 1rem;
            padding: 1rem;
        }
    }

    @include m-respond-to('notebook') {
        .presenter {
            max-width: 20%;
            flex-basis: 20%;

            &__modal {
                width: 50%;
            }

            &__bio {
                font-size: 18px;
            }
        }

        .gh-card {
            flex-flow: row wrap;
            transition: transform .3s;

            &__header,
            &__body {
                flex-basis: 100%;
            }

            &__header {
                height: 200px;
            }

            &__image {
                height: 100%;
            }

            &__name {
                text-align: center;
            }

            &:hover {
                transform: translate3d(0, -1rem, 0);
            }
        }
    }
}