.gh-dashboard--events {
    .gh-card {
        padding: 1rem;

        + .gh-card {
            margin-top: 1rem;
        }

        &__body {
            padding: 0;
        }

        &__thumbnail {
            display: none;
        }

        &__actions {
            display: flex;
            flex-flow: row wrap;

            .gh-button {
                flex-basis: 100%;

                + .gh-button {
                    margin-top: .5rem;
                }
            }
        }
    }

    .event {
        &__title {
            margin-bottom: 1rem;
            color: #565a60;
            font-size: 1.2rem;
        }

        &__details {
            margin-bottom: 1rem;

            .label {
                padding-left: 0;
                font-weight: 600;
            }
        }
    }

    @include m-respond-to('extra-small') {
        .gh-card {
            &__actions {
                flex-flow: row nowrap;
                justify-content: space-between;

                .gh-button {
                    flex-basis: 49%;

                    + .gh-button {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    @include m-respond-to('tablet') {
        .gh-card {
            display: flex;
            align-items: flex-start;

            &__thumbnail {
                display: block;
                flex-basis: 200px;
                height: 100px;
                margin: 0;
                background-position: top left;
                background-size: contain;
            }

            &__body {
                flex-grow: 1;
                padding: 0 1rem;
            }

            &__actions {
                flex-flow: row wrap;
                flex-basis: 170px;
                flex-shrink: 0;

                .gh-button {
                    flex-basis: 100%;

                    + .gh-button {
                        margin-top: .5rem;
                    }
                }
            }
        }
    }
}
