$icon-size: 34px;

.gh-socials {
    display: flex;
}

.gh-social {
    &__item {
        margin-right: 10px;
    }
    
    &__link {
        display: inline-block;
        width: $icon-size;
        height: $icon-size;
        border-radius: 50%;
        color: #fff;
        font-size: 120%;
        line-height: $icon-size;
        text-align: center;
        cursor: pointer;
        
        &.facebook {
            background: #3b5999;
        }
        
        &.twitter {
            background: #54abed;
        }
        
        &.google-plus {
            background: #db4f40;
        }

        &.linkedin {
            background: #007aba;
        }
    }
}

