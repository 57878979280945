.gh-dashboard--wishlist {
    .gh-wishlist__controls {
        margin-bottom: 2rem;
    }

    @include m-respond-to('tablet') {
        .gh-wishlist__controls {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .gh-sort {
            margin-left: 2rem;
        }

        .gh-fuzzy-search {
            margin-bottom: 0;
        }
    }
}
