.gh-page--promo {
    font-family: Catamaran,sans-serif;


    a {
        font-family: Catamaran,sans-serif;
    }

    button {
        font-family: Catamaran,sans-serif;
    }

    .gh-button {
        color: white;
        font-weight: 600;
        padding: .5rem 1.5rem;
        font-size: .9rem;
        border-radius: 3px;
        transition: background-color .5s ease;
        text-transform: none;
        cursor: pointer;
        &.bg-orange {
            background-color: #FE886B;
            &:hover {
                background-color: #FE6B47;
            }
        }

        &.bg-green {
            background-color: #D4E8DA;
            &:hover {
                background-color: #B5D7BF;
            }
        }

        &.text-green {
            color: #055847;
        }
    }

    .justify-center {
        display: flex;
        justify-content: center;
    }

    .gh-hero {
        background: url('/images/promo/promo_banner.jpg') no-repeat left center;
        background-size: cover;
    }

    .gh-bold {
        font-weight: 700;
    }

    .gh-hero__container {
        position: relative;
        height: 360px;
    }

    .gh-hero__content {
        @include m-absolute();

        width: 80%;
        padding: 1rem;
        color: #085078;
        background: rgba(255, 255, 255, 0.8);
    }

    .gh-hero__heading {
        margin-bottom: 1rem;
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.2;
    }

    .gh-hero__subheading {
        margin-bottom: 1rem;
        font-size: 1.5rem;
        line-height: 1.2;
    }

    .gh-section {
        color: #555a60;

        &__heading {
            margin-bottom: 1rem;
        }

        &__subheading {
            margin-bottom: 1rem;
            color: #2a3038;
            font-size: 1.8rem;
            text-align: center;
        }

        &__intro {
            color: #2c3138;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
            font-size: 1.3rem;
            text-align: center;
            font-weight: 500;
        }
    }

    .has-gradient {
        @extend %gradient-venice-blue;
    }

    .cta {
        text-align: center;

        .gh-section__heading,
        .gh-section__subheading,
        .gh-button {
            color: #fff;
        }

        .gh-button {
            border: 1px solid #fff;
            background: transparent;
        }
    }

    .what-you-get {
        background: #fff;

        .listing {
            &__item {
                text-align: center;

                + .listing__item {
                    margin-top: 2rem;
                }
            }
        }

        .item {
            &__icon {
                max-width: 70px;
                margin: 0 auto;
            }

            &__content {
                p {
                    font-weight: 700;
                    font-size: 1rem;
                    line-height: 1.3;
                }
            }
        }
    }

    .intro {
        padding-bottom: 2rem;

        &__text {
            margin: 0 auto;
            color: #2a3038;
            font-weight: 600;
            font-size: 1.5rem;
            text-align: center;
        }
    }

    .mission {
        background: #fff;

        &__content {
            margin: 0 auto;
            text-align: center;
        }

        &__text {
            color: #2a3038;
            font-size: 1.4rem;
            text-align: center;

            .quoted {
                position: relative;
                display: inline-block;
            }
        }

        &__quote {
            position: absolute;
            display: none;
            width: 50px;

            &--left {
                top: -50px;
                left: -50px;
            }

            &--right {
                top: 5px;
                right: -60px;
            }
        }
    }

    .control {
        color: #555a60;
        background: #f4f5f6;

        &__image {
            display: none;
        }

        &__accordion {
            margin-top: 2rem;
        }

        .el-collapse {
            border: 0;

            &-item {
                &.is-active {
                    .accordion__icon {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .el-collapse-item__header {
            display: flex;
            align-items: center;
            height: auto;
            padding: 1rem;
            border: 0;
            background: transparent;
            line-height: 1;
        }

        .el-collapse-item__wrap {
            border-bottom: 0;
            background: transparent;
        }

        .el-collapse-item__header__arrow {
            display: none;
        }

        .el-collapse-item__content {
            font-size: 1rem;
        }

        .accordion {
            &__icon {
                max-width: 30px;
                transition: transform .2s;
            }

            &__heading {
                flex-grow: 1;
                margin-left: 1rem;
                font-weight: 700;
                font-size: 1.2rem;
            }
        }
    }

    .cta-alt {
        color: #fff;
        background: #3e4557;
        text-align: center;

        .cta__icon {
            display: none;
        }

        .cta__text {
            font-size: 2rem;
        }

        .gh-button {
            margin-top: 2rem;
        }
    }

    .learning-modes {
        color: #555a60;
        background: #e5e5e5;

        &__listing {
            margin-top: 2rem;
        }

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }

        .lm {
            text-align: center;

            + .lm {
                margin-top: 2rem;
            }

            &__body {
                text-align: left;
            }

            &__icon {
                max-width: 80px;
                margin: 0 auto;
            }

            &__heading {
                margin-bottom: .5rem;
                font-weight: 700;
                font-size: 1.5rem;
            }
        }
    }

    .experts {
        color: #555a60;
        background: #fff;

        &__content {
            margin-top: 2rem;
        }

        &__heading {
            margin-bottom: .5rem;
            font-weight: 600;
            font-size: 1.3rem;
            line-height: 1;
        }

        [class^='col-'] {
            margin-bottom: 1rem;
        }

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }
    }

    .impact {
        background: #f4f5f6;

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }

        &__content {
            margin-top: 1rem;

            p {
                + p {
                    margin-top: .5rem;
                }
            }
        }
    }

    .gh-testimonials2 {
        background: white;
        padding-bottom: 3.5rem;
        .gh-section__heading {
            font-size: 2.56rem;
            margin-top: .5rem;
            margin-bottom: 1rem;
        }

        .swiper-container {
            margin-top: 1rem;
            padding-bottom: 5rem;
        }

        .swiper-slide {
            height: auto !important;
        }

        .gh-testimonial-sliders {
            .swiper-wrapper {
              height: 100%;
            }

            .swiper-button {
              margin-top: -4rem;
              width: 60px;
              height: 60px;
              img {
                width: 24px;
                height: 24px;
              }
            }

            .swiper-pagination-bullet-active {
              background-color: $pallete-dark-blue;
            }

            .swiper-pagination-bullet {
                width: 8px;
                height: 8px;
                background: transparent;
                border: 1px solid #000;
                &:nth-child(2n) {
                    display: none;
                }
            }

            .swiper-pagination-bullet-active {
                background-color: #9A9A9A;
                border-color: #9A9A9A;
            }
          }

        .v-card {
            padding: 1.5rem;
            border-radius: 10px;
            box-shadow: 0px 0px 5px 2px rgba(222,223,224,0.5);
            display: block;
            width: 100%;
            height: 100%;
            background: #fff;
            transition: box-shadow .6s cubic-bezier(.165,.84,.44,1);
            cursor: pointer;
            user-select: none;
            position: relative;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
            margin-top: 1rem;
            .v-title {
                img {
                    margin-bottom: 1.3rem;
                }
            }
            .v-title-text {
                color: black;
                font-size: 1.3rem;
            }
            .v-content-text {
                color: #676767;
                font-size: 1.2rem;
                line-height: 1.2;
            }
            .v-author {
                color: black;
                font-size: 1.1rem;
            }
        }
    }

    .offers {
        .gh-section__heading,
        .gh-section__subheading {
            color: #fff;
            //text-align: left;
        }

        .text-highlight {
            color: #fff;
            font-size: 1.5rem;
            line-height: 1.2;
        }

        .divider {
            margin: 1rem 0;
            border-top: 2px solid #fff;
        }

        &__listing {
            margin-top: 5rem;
        }

        .offer {
            margin-top: 2rem;

            &__content {
                position: relative;
                margin-top: .5rem;
                padding: 1rem;
                background: #fff;
                box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
            }

            &__save {
                position: absolute;
                top: -70px;
                right: 0;
                max-width: 110px;
            }

            &__heading {
                max-width: 60%;
                color: #fff;
                font-size: 1.2rem;
            }

            &__ribbon,
            &__or {
                display: none;
            }

            &__highlight {
                margin: 2rem 0 1rem;
                color: $brand-secondary;
                font-weight: 600;
            }

            ul {
                padding-left: 20px;
                list-style: disc;
            }

            &__investment {
                margin-top: 1rem;
                color: $brand-secondary;

                span {
                    margin-right: .5rem;
                    font-weight: 600;
                }

                p {
                    font-size: 1.5rem;
                }
            }

            &__action {
                margin-top: 2rem;
                color: #fff;
                text-align: center;

                .gh-button {
                    margin-bottom: 1rem;
                    color: #fff;
                    background: #2c3138;
                }

                p {
                    font-size: .8rem;
                }

                a {
                    color: #fff;
                    font-weight: 600;
                }
            }
        }

        .offer-conditions {
            p {
                + p {
                    margin-top: 1rem;
                }
            }
        }
    }

    .gh-promo-testimonials {
        padding: 0 1rem;
    }

    .gh-form {
        .gh-section__description {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
    }

    .gh-form-registration-promo {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        .c-form__label {
            display: block;
            margin-bottom: 9px;
            font-weight: 700;
            font-size: 1.15rem;
            line-height: 1;
            color: #676767;
        }

        .c-form__heading {
            color: #055648;
            font-size: 1.65rem;
            font-weight: 700;
        }

        .el-form-item__content:focus-within, .el-form-item.has-value {
            .c-form__label {
              color: #FE8D71;
            }
          }

        .el-form-item {
            margin-bottom: 27px;
        }

        .el-form-item.has-value .c-form__label {
            color: #676767;
        }
        .el-input .el-input__inner {
            background: #fff;
            font-size: 1.1rem;
            height: 55px!important;
            border: none;
            border-radius: 5px;

            &:focus {
                border: 2px solid #FE8D71;
                border-radius: 8px;
            }
        }

        .c-form__section-stripe {
            .gh-stripe {
                background: white;
            }

            .gh-stripe__element {
                border: none;
                height: 55px;
                padding-top: 1.3rem;
            }
        }

        .gh-stripe__error {
            background: #F9F6F1;
        }

        .gh-button {
            text-transform: capitalize !important;
            padding: 0.5rem 3rem;
            font-weight: 700;
            font-size: 1rem;
        }

        .gh-bottom {
            .c-form__heading {
                font-size: 1.2rem;
            }

            .gh-price {
                font-weight: 500;
            }

            .el-checkbox {
                color: #075D4C;

                .el-checkbox__inner {
                    width: 30px;
                    height: 30px;
                    margin-top: -0.3rem;
                    border-radius: 5px;
                    &::after {
                        left: 9px;
                        width: 8px;
                        height: 17px;
                    }
                }

                a {
                    color: #075D4C;
                    text-decoration: underline;
                }
                &.is-checked {
                    .el-checkbox__inner {
                        background-color: #075D4C;
                        border-color: #075D4C;
                    }
                }
            }
        }
    }

    .offers__cpd {
        .gh-section__heading,
        .gh-section__subheading {
            color: #fff;
        }

        .text-highlight {
            color: #fff;
            font-size: 1.5rem;
            line-height: 1.2;
        }

        .divider {
            margin: 1rem 0;
            border-top: 2px solid #fff;
        }

        &__listing {
            margin-top: 5rem;
        }

        .offer {
            margin-top: 2rem;

            .offer__heading {
                margin: 1rem 0;
                font-size: 1.6rem;
                font-weight: 400;
                color: #514a9d;
            }

            &__content {
                position: relative;
                margin-top: .5rem;
                padding: 1rem;
                background: #fff;
                box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
            }

            &__save {
                position: absolute;
                top: -50px;
                right: 0;
                max-width: 110px;
            }

            &__heading {
                max-width: 60%;
                color: #fff;
                font-size: 1.2rem;
            }

            ul {
                padding-left: 20px;
                list-style: disc;
            }

            &__investment {
                margin-top: 1rem;
                color: $brand-secondary;

                span {
                    margin-right: .5rem;
                    font-weight: 600;
                }

                p {
                    font-size: 1.5rem;
                }
            }

            &__action {
                margin-top: 2rem;
                color: #fff;
                text-align: center;

                .gh-button {
                    margin-bottom: 1rem;
                    color: #fff;
                    background: #2c3138;
                }

                p {
                    font-size: .8rem;
                }

                a {
                    color: #fff;
                    font-weight: 600;
                }
            }
        }

        .offer-conditions {
            p {
                + p {
                    margin-top: 1rem;
                }
            }
        }
    }

    .reasons {
        background: #fff;
        text-align: center;

        &__stats {
            margin-top: 2rem;
        }

        .stat {
            margin-bottom: 1rem;
            color: $brand-primary;

            &__number {
                font-weight: 700;
                font-size: 3rem;
            }

            &__text {
                font-weight: 600;
                font-size: 1.2rem;
            }
        }

        &__details {
            .heading {
                font-weight: 700;
                font-size: 1.2rem;
            }
        }

        .details {
            margin: 2rem 0;
        }

        .detail {
            margin-bottom: 1rem;
            text-align: center;

            &__icon {
                max-width: 80px;
                margin: 0 auto;
            }

            &__content {
                margin-top: .5rem;
            }

            &__heading {
                font-weight: 700;
                font-size: 1.2rem;
            }
        }
    }

    .testimonials {
        background: #e5e5e5;

        .gh-section__subheading {
            margin-bottom: 2rem;
        }

        .testimonial {
            width: 100%;

            &__content {
                padding: 1rem;
                border-bottom: 5px solid;
                background: #fff;
            }
        }

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }
    }

    .start {
        text-align: center;

        &__cta{
            margin: 2rem 0;
        }
    }

    .gh-page--registration {
        margin-top: 2rem;
        text-align: left;
    }

    @include m-respond-to('extra-small') {
        .what-you-get {
            .listing {
                display: flex;
                justify-content: space-between;
                flex-flow: row wrap;

                &__item {
                    flex-basis: 50%;
                    max-width: 50%;
                    padding: 1rem;

                    + .listing__item {
                        margin-top: 0;
                    }
                }
            }
        }

        .offers {
            .offer {
                + .offer {
                    margin-top: 5rem;
                }

                &__heading {
                    max-width: 100%;
                }
            }
        }

        .offers__cpd {
            .offer {
                + .offer {
                    margin-top: 5rem;
                }

                &__heading {
                    max-width: 100%;
                }
            }
        }
    }

    @include m-respond-to('small') {
        .offers {
            .offer {
                &__ribbon {
                    position: absolute;
                    right: 2rem;
                    bottom: 2rem;
                    display: block;
                    max-width: 100px;
                }

                ul {
                    max-width: 70%;
                }
            }
        }

        .offers__cpd {
            .offer {
                ul {
                    max-width: 70%;
                }
            }
        }
    }

    @media only screen and (min-width: '768px') {
        .gh-button {
            font-size: 1.1rem;
        }

        .gh-form-registration-promo {
            padding-left: 2rem !important;
            padding-right: 2rem !important;
        }
        .gh-form {
            .gh-section__description {
                padding-left: 2rem !important;
                padding-right: 2rem !important;
            }
        }
    }

    @include m-respond-to('tablet') {
        .learning-modes {
            &__listing {
                display: flex;
                flex-flow: row wrap;
            }

            .lm {
                flex-basis: 50%;
                max-width: 50%;
                margin-bottom: 1rem;
                padding: 1rem;

                + .lm {
                    margin-top: 0;
                }
            }
        }

        .reasons__stats {
            display: flex;
            flex-flow: row wrap;
        }

        .stat {
            flex-basis: 33.33%;
            max-width: 33.33%;
            padding: 1rem;
        }

        .testimonials {
            .testimonial {
                max-width: calc(100% / 3 - 16px * 2);
            }
        }

        .offers__cpd {
            .offer {
                &__save {
                    top: -65px;
                    right: calc(50% - 55px);
                }
            }
            &offers__listing {
                margin-top: 3rem;
            }
        }

        .gh-testimonials2 {
            .swiper-container {
                padding-bottom: 33rem;
            }

            .gh-testimonial-sliders {
                height: 619px !important;

                .swiper-slide {
                    height: 529px !important;
                }
            }

            .v-card {
                position: relative;
                padding: 2.7rem 2.5rem 10rem 2.5rem;

                .v-footer {
                    position: absolute;
                    bottom: 2rem;
                }
            }
        }
    }

    @media only screen and (min-width: 900px) {
        .gh-testimonials2 {
            .swiper-container {
                padding-bottom: 31rem;
            }

            .gh-testimonial-sliders {
                height: 574px !important;

                .swiper-slide {
                    height: 490px !important;
                }
            }
        }
    }

    @include m-respond-to('notebook') {
        .mission {
            &__quote {
                display: block;
            }
        }

        .control {
            overflow: hidden;

            .gh-section__subheading {
                text-align: left;
            }

            &__container {
                position: relative;
                display: flex;
            }

            &__content {
                flex-basis: 60%;
                max-width: 60%;
                z-index: 1;
            }

            &__image {
                position: absolute;
                top: -50px;
                left: 50%;
                display: flex;
                width: 580px;
                height: 580px;
                border-radius: 50%;
                background: #f9f9fa;

                img {
                    max-width: 300px;
                    margin: 0 auto;
                }
            }

            .el-collapse-item__header {
                padding: .5rem 0;
            }

            .el-collapse-item__wrap {
                padding-left: 2rem;
            }
        }

        .learning-modes {
            .gh-section__subheading {
                text-align: left;
            }

            .lm {
                display: flex;
                align-items: flex-start;
                margin-bottom: 2rem;
                padding: 0;

                &__icon {
                    flex-basis: 70px;
                    max-width: 70px;
                    flex-shrink: 0;
                }

                &__content {
                    padding: 0 1rem;
                    text-align: left;
                }
            }

            &__cta {
                margin-top: 0;
            }
        }

        .experts {
            .gh-section__subheading {
                text-align: left;
            }

            .col-md-6 {
                padding-right: 2rem;
            }
        }

        .impact {
            padding: 0;
            background: radial-gradient(circle at right, transparent 40%, rgba(244, 246, 246, 1) 50%), url('/images/app/full-social-impact-image.jpg');
            background-position: center center, right center;
            background-size: contain, 700px;
            background-repeat: no-repeat;

            &__wrapper {
                max-width: 50%;
                padding: 2rem 0;
            }

            .gh-section__subheading,
            &__cta {
                text-align: left;
            }
        }

        .reasons {
            &__stats {
                margin-top: 1rem;
            }

            .stat {
                margin-bottom: 2rem;

                &__number {
                    font-size: 5rem;
                }
            }

            .heading {
                max-width: 60%;
                margin: 0 auto;
            }

            .detail {
                display: flex;
                align-items: flex-start;
                margin-bottom: 2rem;

                &__icon {
                    flex-basis: 70px;
                    max-width: 70px;
                    flex-shrink: 0;
                    margin: 0
                }

                &__content {
                    margin: 0;
                    padding: 0 1rem;
                    text-align: left;
                }
            }
        }

        .gh-hero {
            &__content {
                width: 60%;
                background: transparent;
                text-align: center;
            }
        }

        .cta,
        .cta-alt {
            > .container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .left {
                .gh-section__heading {
                    text-align: left;
                }

                .gh-section__subheading,
                .gh-section__heading {
                    margin-bottom: 0;
                }
            }

            .right {
                .gh-button {
                    margin-top: 0;
                }
            }
        }

        .what-you-get {
            .listing__item {
                flex-basis: 25%;
                max-width: 25%;
            }
        }

        .offers {
            .gh-section__heading,
            .text-highlight,
            .gh-section__subheading {
                text-align: center;
            }

            &__listing {
                display: flex;
                justify-content: space-between;
                max-width: 960px;
                margin: 5px auto 0;
            }

            .offer {
                flex-basis: 50%;
                max-width: 50%;
                margin-top: 0;
                padding: 1rem;

                + .offer {
                    margin-top: 0;
                }

                &__content {
                    padding: 2rem;
                }

                &__highlight {
                    max-width: 50%;
                    margin-top: 0;
                }

                &__save {
                    top: -50px;
                    right: 20px;
                }
            }
        }

        .offers__cpd {
            .gh-section__heading,
            .text-highlight,
            .gh-section__subheading {
                text-align: center;
            }

            &__listing {
                display: flex;
                justify-content: space-between;
            }

            .offers__listing {
                margin-top: 4rem;
            }

            .offer {
                flex-grow: 1;
                flex-basis: 0;
                max-width: 100%;
                margin-top: 0;
                padding: 1rem;

                .offer__heading {
                    margin-top: 0;
                }

                + .offer {
                    margin-top: 0;
                }

                &__content {
                    padding: 2rem;
                }

                &__highlight {
                    max-width: 50%;
                    margin-top: 0;
                }
            }
        }

        .gh-testimonials2 {
            .swiper-container {
                padding-bottom: 38rem;
            }

            .gh-testimonial-sliders {
                height: 517px !important;

                .swiper-slide {
                    height: 503px !important;
                }
            }
        }
    }

    @include m-respond-to('medium') {
        .gh-hero {
            &__container {
                height: 500px;
            }

            &__heading {
                font-size: 2.8rem;
            }

            &__subheading {
                font-size: 2.2rem;
            }
        }

        .impact {
            padding: 0;
            background: radial-gradient(circle at right, transparent 25%, #f4f6f6 35%), url("/images/app/full-social-impact-image.jpg");
            background-position: center center, right center;
            background-size: contain, 700px;
            background-repeat: no-repeat;
        }

        .offers {
            &__listing {
                position: relative;
                margin-top: 4rem;
            }

            .offer {
                ul {
                    max-width: 90%;
                }

                &__ribbon {
                    right: 1rem;
                }

                &__or {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    color: #fff;
                    background: #2c3138;
                    font-size: 1.5rem;
                    line-height: 50px;
                    text-align: center;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
        }

        .offers__cpd {
            &__listing {
                position: relative;
                margin: 1rem auto 0;
            }

            .offers__listing {
                margin-top: 2rem;
            }

            .offer {
                &__save {
                    top: 10px;
                    right: calc(50% - 55px);
                }

                ul {
                    max-width: 90%;
                }
            }
        }
    }

    @media only screen and (min-width: 1024px) {
        .gh-form-registration-promo {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .gh-form {
            .gh-section__description {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }

    @media only screen and (min-width: 1112px) {
        .gh-testimonials2 {
            .swiper-container {
                padding-bottom: 35rem;
            }

            .gh-testimonial-sliders {
                height: 660px !important;

                .swiper-slide {
                    height: 572px !important;
                }
            }
        }
    }


    @media only screen and (min-width: 1366px) {
        .gh-testimonials2 {
            .swiper-container {
                padding-bottom: 32rem;
            }

            .gh-testimonial-sliders {
                height: 554px !important;

                .swiper-slide {
                    height: 469px !important;
                }
            }
        }
    }

    @media only screen and (min-width: 1500px) {
        .gh-testimonials2 {
            .gh-testimonial-sliders {
                height: 633px !important;

                .swiper-slide {
                    height: 546px !important;
                }
            }
        }
    }

    @media only screen and (min-width: 1600px) {
        .gh-testimonials2 {
            .swiper-container {
                padding-bottom: 34rem;
            }

            .gh-testimonial-sliders {
                .swiper-button-prev {
                    margin-left: 4rem;
                }

                .swiper-button-next {
                    margin-right: 4rem;
                }
            }
        }
    }
}
