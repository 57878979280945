.gh-dashboard--details {
    .gh-form--membership-details {
        margin-top: 2rem;

        .el-input__inner {
            background: #fff;

            &[readonly] {
                background: #e5e5e5;
                cursor: inherit;

                &:focus,
                &:hover {
                    border-color: transparent;
                }
            }
        }

        .gh-form__actions {
            .gh-button {
                + .gh-button {
                    margin-top: 1rem;
                    margin-left: 1rem;
                }
            }
            @media screen and (max-width: '440px') {
                .gh-button + .gh-button {
                    margin-left: 0;
                }
            }
        }

        .gh-button--edit {
            color: #fff;
            background: #514a9d;

            &.is-disabled {
                background: #ddd;

                &:hover {
                    background: #ccc;
                }
            }

            &:hover {
                background: #474083;
            }
        }
    }

    .credit-card-update {
        margin-top: 2rem;

        .gh-stripe {
            margin: 2rem 0;

            &__label {
                font-weight: 500;
                font-size: 1.2rem;
            }
        }
    }

    @include m-respond-to('tablet') {
        .gh-form--membership-details {
            .gh-form__group {
                display: flex;
                justify-content: space-between;
            }

            .el-form-item {
                flex-basis: 49%;
            }
        }
    }
}
