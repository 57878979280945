.gh-page--dashboard {
    padding-bottom: 2rem;
    font-family: Catamaran,sans-serif;

    .gh-page {
        &__body {
            position: relative;
        }

        &__sidebar {
            margin-bottom: 0;
        }
        
        &__heading {
            text-align: left;
        }
    }

    .gh-sidebar__actions {
        display: flex;
        flex-flow: row wrap;
        margin-top: 1rem;

        .gh-button {
            flex-basis: 100%;

            + .gh-button {
                margin-top: 1rem;
            }
        }
    }

    @include m-respond-to('notebook') {
        .gh-page {
            &__main {
                padding-left: 1rem;
            }
        }

        .gh-sidebar__actions {
            display: block;
            margin-top: 2rem;

            .gh-button {
                display: block;
                width: 100%;

                + .gh-button {
                    margin-top: .5rem;
                }
            }
        }
    }
}
