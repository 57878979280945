.gh-page--catalogue {
    .container {
        position: relative;
    }

    .gh-clear-filter-link {
        cursor: pointer;
        color: $pallete-dark-grey;
    }

    .gh-exclude-and-clear-filter {
        position: absolute;
        right: 0rem;
        top: -2rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &.is-member {
            justify-content: space-between;
        }

        .el-checkbox {
            margin-left: .7rem;
            .el-checkbox__input.is-checked .el-checkbox__inner {
                background-color: #4D4796;
                border-color: #4D4796;
            }
    
            .el-checkbox__label {
                font-weight: 500;
                color: $pallete-dark-grey;
                word-break: keep-all;
            }
        }
    }

    .gh-filters--desktop {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        &__label {
            margin-right: 1rem;
            color: #85888D;
            font-weight: 600;
        }
        .gh-button-redesign {
            background: #E5E5E5;
            padding: .5rem 1rem;
            font-weight: 600;
        }
        .gh-filters__group {
            border-bottom: none;
            + .gh-filters__group {
                margin-top: 0;
                margin-left: 1rem;
            }
        }
    }
    .gh-page__heading {
        margin-top: 2rem;
        margin-bottom: .5rem;
        color: $pallete-dark-blue;
        text-align: left;
        font-weight: 900;
        font-size: 2.7rem;
    }

    .gh-scout {
        margin-bottom: 2rem;
    }

    
    .gh-section-sort {
        align-items: center;
        button {
            padding: .65rem;
            font-weight: 500;
            span {
                padding-right: .5rem;
            }
        }

        .el-checkbox {
            margin-right: -1rem;
            ::v-deep(.el-checkbox__label) {
                font-family: proxima-nova, sans-serif;
                font-weight: 500;
            }
        }

        .gh-button-redesign {
            border: 1px solid #E5E5E5;
            border-radius: 2px;
            padding: .5rem 1rem;
            @media(max-width: 403px) {
                padding: .5rem .6rem;
            }
        }

        .gh-sort{
            .is-selected-dropdown {
                z-index: 20000;
                background: white;
                color: #091222;
                border-bottom-right-radius: 0;
                padding-bottom: .6rem;
                border-bottom: none;
            }
            .is-not-selected-dropdown {
                background: #EFEFEF;
                color: #39464D;
                padding-bottom: .5rem;
            }
        }

        .gh-listing-style-view {
            margin-right: .5rem;
            i {
                display: flex;
            }
            button {
                border: 1px solid #E5E5E5;
                border-radius: 2px;
            }
        }
    }

    .gh-topic-filter {
        box-shadow: 0px 0px 5px 2px rgba(222,223,224,0.5);
        border-radius: 2px;
        .gh-filter__body {
            padding: 1.5rem;
            padding-top: 1rem;
        }
        .gh-filter__heading {
            font-size: 1.5rem;
            font-weight: 600;
            padding-bottom: 1rem;
        }
        .gh-button-redesign {
            margin-top: 3rem;
            margin-bottom: .5rem;
            color: #13517B;
            border-color: #13517B;
            padding: 1rem 1.5rem;
        }
        .gh-filter-item {
            display: flex;
            align-items: flex-start;
            white-space: normal;
            font-size: 1rem;
            word-break: keep-all;
            font-family: proxima-nova, sans-serif;
            margin-right: 0;
            + .gh-filter-item {
                margin-top: .5rem;
                margin-left: 0;
            }
            .el-checkbox__input.is-checked + .el-checkbox__label {
                color: inherit;
            }
        }

        .el-checkbox {
            font-size: 1.1rem;
            color: #313640;
        }
    
        .el-checkbox__input {
            margin-top: .2rem;
        }
    
        .el-checkbox__label {
            padding-left: 1rem;
            font-weight: 500;
            font-size: 1rem;
        }

        .gh-topic-filter__dialog {
            max-height: 400px;
            max-width: 350px;
            overflow-y: scroll;
            .el-dialog__header {
                position: fixed;
                max-width: inherit;
                width: 100%;
                z-index: 2;
                background: white;
                .gh-filter--mobile__header p {
                    font-size: 1.3rem;
                    font-weight: 600;
                }
            }
            .el-dialog__body {
                margin: 2rem 0;
            }
            &::-webkit-scrollbar {
                width: 15px;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                background: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                border: 4px solid transparent;
                border-radius: 9px;
                background-clip: content-box;
            }
            .gh-topics-search {
                color: #B0B1B5;
                margin: 1rem 0;
                .el-input__inner {
                    background: white;
                    &::placeholder {
                        color: #B0B1B5;
                    }
                }
            }
        }
    }

    .gh-filters--desktop {
        .gh-split-line {
            padding: 0 .3rem;
        }
        .gh-filters {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            &__label {
                margin-right: 1rem;
                color: #85888D;
                font-weight: 600;
            }
            .gh-button-redesign {
                background: #E5E5E5;
                padding: .5rem 1rem;
                font-weight: 600;
            }
            .el-dropdown {
                + .el-dropdown {
                    margin-left: 1rem;
                }
            }
        }
    }

    .subfilters {
        margin-bottom: 2rem;
    }

    
    .gh-author-search {
        color: #B0B1B5;
        margin: 1rem 0;
        .el-input__inner {
            background: white;
            &::placeholder {
                color: #B0B1B5;
            }
        }
    }

    .gh-course-search {
        input {
            font-family: proxima-nova, sans-serif;
        }
    }

    .gh-mobile-listing-info {
        margin: 1rem 0;
    }

    .gh-filters--mobile {
        .el-dialog {
            .el-dialog__header {
                position: fixed;
                width: 100%;
                z-index: 2;
                background: white;
                .gh-filter--mobile__header p {
                    font-size: 1.3rem;
                    font-weight: 600;
                }
            }
            .el-dialog__body {
                margin: 3rem 0;
                .gh-filter--mobile__exclude-courses {
                    display: flex;
                    align-items: center;
                    margin-bottom: 2rem;
                    width: fit-content;
                    .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #4D4796;
                        border-color: #4D4796;
                    }
                }

                .el-collapse-item__header {
                    font-size: 1.3rem;
                    font-weight: 600;
                }

                .el-collapse-item__arrow {
                    display: block;
                }
                .el-checkbox {
                    word-break: keep-all;
                    margin-right: 1rem;
                    .el-checkbox__label {
                        font-size: 1.2rem;
                    }
                    .el-checkbox__inner {
                        font-size: 1.2rem;
                        color: #606266;
                        border-color: #606266;
                    }
                    + .el-checkbox {
                        margin-top: 1.5rem;
                    }
                }
            }
            .el-dialog__footer {
                position: fixed;
                bottom: 0;
                background: white;
                width: 100%;
                z-index: 2;
                button {
                    color: #fff;
                    background: #514a9d;
                    width: 100%;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
            }
        }
    }

    .gh-tags {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ccc;
    }

    .gh-course-listing {
        margin-left: -.5rem;
        margin-right: -.5rem;
        margin-top: 1rem;
        &__info {
            font-weight: 500;
            font-size: 1rem;
            color: #868a8f;
        }

        &__results {
            display: flex;
            flex-flow: row wrap;
            margin-bottom: 2rem;
        }

        &__item {
            flex-grow: 1;
            padding: .5rem;

            &.flip-move {
                transition: transform .6s;
            }
        }

        &__list-results {
            display: flex;
            flex-flow: column;
            margin-bottom: 2rem;

            .gh-list--program {
                background: white;
                cursor: pointer;
                .gh-list__body {
                    display: flex;
                    justify-content: space-between;
                    background: white;
                    padding: 1.5rem;
                    box-shadow: 0 0 7px rgba(0, 0, 0, .1);
                    border: none;
                    border-radius: 2px;
                    margin-bottom: .8rem;
                    transition: box-shadow .3s, transform .3s;
                    &:hover {
                        transform: translate3d(0, -3px, 0);
                        box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
                    }
                    .program__title {
                        padding-right: 2rem;
                        span + span {
                            border-left: 2px solid #13517B;
                            padding: .4rem 1rem;
                        }
                    }
                    &__title {
                        font-size: 1.2rem;
                        font-weight: 700;
                        color: #13517B;
                        padding-right: 1rem;
                    }
                    &__duration {
                        color: #5D6068;
                    }
                    &__format {
                        display: flex;
                        flex-flow: row nowrap;
                    }
                }
            }
        }

        &__more {
            display: flex;
            justify-content: center;
            margin-bottom: 2rem;

            .gh-button {
                + .gh-button {
                    margin-left: 1rem;
                }
            }
        }
    }

    @include m-respond-to('extra-small') {
        .gh-course-listing {
            &__item {
                flex-basis: 50%;
                flex-grow: 0;
            }
        }
    }

    @include m-respond-to('tablet') {
        .gh-page__heading {
            margin-top: 3rem;
        }
    }

    @include m-respond-to('notebook') {
        .gh-page__main {
            padding-left: 2rem;
        }

        .subfilters {
            display: flex;
            justify-content: space-between;
        }

        .gh-scout {
            flex-grow: 1;
            margin: 0 2rem 0 0;
        }

        .gh-sort {
            display: flex;
            flex-flow: column;
            flex-basis: 120px;
            flex-shrink: 0;
        }

        .gh-course-listing {
            margin-top: 2rem;
            margin-left: -.5rem;
            margin-right: -1rem;

            &__item {
                max-width: 33%;
                flex-grow: 0;
                flex-basis: 33%;
            }
        }
    }

    @include m-respond-to('medium') {
        .gh-topic-filter {
            .gh-button-redesign {
                padding: 1rem 3.4rem;
            }
        }
    }
}

