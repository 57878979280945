.gh-page--memberships {
    .memberships {
        margin: 4rem 0;
        padding: 1rem;
        background: #fff;

        &__features {
            display: none;
        }

        &__package {
            max-width: 400px;
            margin: 0 auto;
            text-align: center;

            + .memberships__package {
                margin-top: 4rem;
            }
        }
    }

    .package {
        &__name {
            margin-bottom: .5rem;
            font-weight: 300;
            font-size: 2.5rem;
        }

        &__ribbon {
            width: 90%;
            margin: 0 auto;
            padding: 2px;
            color: #fff;
            background: $brand-primary;
            font-weight: 600;
            font-size: 1.2rem;
        }

        &__pricings {
            padding: .5rem;
            color: #fff;
            background: #565a60;

            .price {
                display: block;
                font-weight: 600;
                font-size: 1.2rem;
                line-height: 1.3;
            }
        }

        &__body {
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
        }

        &__features {
            .feature {
                &:nth-child(odd) {
                    background: #e5e5e5;
                }

                &:nth-child(even) {
                    background: #f6f6f6;
                }
            }
        }
    }

    .feature {
        display: none;
        padding: .5rem;

        + .feature {
            border-top: 1px solid #ccc;
        }

        &--mobile {
            display: flex;
            font-weight: 500;
            text-align: left;
        }

        &__status {
            display: none;
        }

        &__icon {
            flex-basis: 20px;
            flex-shrink: 0;
            margin-right: 10px;
            font-size: 18px;
        }

        &__label {
            font-weight: 500;
            line-height: 1.5;
        }
    }

    .gh-button--cta {
        display: block;
        background: #00c478;
    }

    @include m-respond-to('extra-small') {
        .feature {
            align-items: center;
        }
    }

    @include m-respond-to('notebook') {
        .memberships {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            max-width: 90%;
            margin: 0 auto 2rem;
            padding: 2rem;

            &__features {
                display: block;
                flex-grow: 1;
                margin-bottom: 40px;

                .feature {
                    display: flex;
                    height: 41px;
                    color: #565a60;
                }
            }

            &__package {
                flex-basis: 200px;
                flex-shrink: 0;
                max-width: none;
                margin: 0;

                + .memberships__package {
                    margin-top: 0;
                }
            }
        }

        .memberships__package--best {
            .package {
                &__name {
                    max-width: 100%;
                    border-bottom: 0;
                }

                &__pricings {
                    padding: 1rem;
                    background: #2c3138;
                }

                &__features {
                    .feature {
                        box-shadow: none;

                        &:nth-child(odd) {
                            background: #f6f6f6;
                        }

                        &:nth-child(even) {
                            background: #fff;
                        }
                    }
                }
            }

            .gh-button {
                box-shadow: 10px 8px 6px 0 rgba(0, 0, 0, .2);
            }


            .package__body {
                box-shadow: 10px 0 10px rgba(0, 0, 0, .3);
            }
        }

        .package {
            &__name {
                max-width: 80%;
                margin: 0 auto;
                border-bottom: 7px solid #085078;
                font-size: 2rem;
            }

            &__pricings {
                box-shadow: inset 14px 0 6px -5px rgba(0, 0, 0, 0.2);
            }

            &__body {
                box-shadow: none;
            }

            &__features {
                .feature {
                    box-shadow: inset 14px 0 6px -5px rgba(0, 0, 0, .2);
                }
            }
        }

        .gh-button {
            box-shadow: inset 14px 0 6px -5px rgba(0, 0, 0, 0.2);
        }

        .feature {
            display: block;

            &--mobile {
                display: none;
            }

            &__status {
                display: block;

                &.is-green {
                    color: #00c478;
                }

                &.is-red {
                    color: #ff0000;
                }
            }
        }
    }
}