.sessions {
    background: #fff;

    &__summary {
        margin-bottom: 2rem;

        p {
            + p {
                margin-top: 1rem;
            }
        }
    }
}

.timeline {
    margin-bottom: 2rem;

    &__details {
        position: relative;
        padding: 0 0 1rem 1.5rem;
        border-left: 1px solid #95989c;
        cursor: pointer;

        &::before {
            position: absolute;
            top: 0;
            left: -10px;
            display: block;
            width: 20px;
            height: 20px;
            border: 1px solid #95989c;
            border-radius: 50%;
            content: '';
            background: #fff;
            transition: .3s;
        }
    }

    &__time {
        color: #95989c;
        font-weight: 600;
        transition: .3s;
    }
}

.session {
    &__time {
        display: none;
        color: #95989c;
        font-weight: 600;
    }

    &__title {
        margin: 1rem 0;
        color: #474f59;
        font-size: 1.2rem;
        line-height: 1.5;
        transition: .3s;
    }

    &__order {
        margin-right: 5px;
        padding-right: 10px;
        border-right: 3px solid #e5e5e5;
    }

    &__description {
        color: #565a60;
        font-weight: 500;
    }

    &.is-active {
        .session {
            &__title {
                color: $brand-primary;
            }
        }

        .timeline {
            &__time {
                color: #474f59;
            }
            
            &__details {
                &::before {
                    background: #95989c;
                }
            }
        }
    }

    @include m-respond-to('notebook') {
        display: flex;

        &__time {
            display: block;
            flex-basis: 170px;
            flex-shrink: 0;
            cursor: pointer;

            p {
                color: #95989c;
                font-weight: 700;
                font-size: 18px;
                transition: color .3s;
            }
        }

        &__title {
            margin-top: 0;
            font-size: 22px;
        }

        &__description {
            font-size: 18px;
            line-height: 1.5;
        }

        &.is-active {
            .session__time {
                color: $brand-primary;

                p {
                    color: #565a60;
                }
            }
        }

        .timeline {
            &__time {
                display: none;
            }
        }
    }
}

