.gh-breadcrumb {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 1rem 0;
    font-weight: 500;
}

.gh-breadcrumb__separator {
    margin: 0 .5rem;
}

.gh-breadcrumb__item__inner {
    color: #97a8be;
    line-height: 1.5;

    a {
        color: #aaa;
        font-weight: inherit;
        transition: .15s;

        &:hover {
            color: $brand-primary;
        }
    }
}


.gh-page-breadcrumb {
    .container {
        padding-top: 0;
        padding-bottom: 0;
    }
    .gh-breadcrumb {
        padding-top: .4rem;
        padding-bottom: .4rem;
    }

    .gh-breadcrumb__item {
        font-family: proxima-nova, sans-serif;
        font-weight: 600;
        font-size: 0.9rem;
    }
    .gh-breadcrumb__item__inner {
        color: $pallete-dark-grey;
    }
}