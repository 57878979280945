.event__presenter {
    //display: flex;
    .a-presenter__avatar {
        width: 100%;
        //float: left;
    }

    .a-presenter__bio{
        width: 100%;
        padding: 5%;
        p{
            margin-bottom: 15px;
        }
        .subheading {
            color: #514a9d;
            font-size:30px;
        }
        .heading {
            margin-bottom: 30px;
            font-size: 40px;
            font-weight: 500;
        }
    }
    @include m-respond-to('tablet') {
        align-items: center;
        display: flex;
        .a-presenter__avatar {
            width: 40%;
            padding-left: 20px;
        }

        .a-presenter__bio{
            padding: 5%;
            //width: 80%;
            p{
                margin-bottom: 15px;
            }
            .subheading {
                color: #514a9d;
                font-size:30px;
            }
            .heading {
                margin-bottom: 30px;
                font-size: 40px;
                font-weight: 500;
            }
        }
    }

    @include m-respond-to('notebook') {
         display: flex;
         .a-presenter__avatar {
             width: 40%;
             padding-left: 0;
         }

         .a-presenter__bio{
             padding: 3% 5%;
             p{
                 margin-bottom: 15px;
             }
             .subheading {
                 color: #514a9d;
                 font-size:30px;
             }
             .heading {
                 margin-bottom: 30px;
                 font-size: 40px;
                 font-weight: 500;
             }
         }
     }

    @include m-respond-to('medium') {
        display: flex;
        .a-presenter__avatar {
            width: 40%;
        }

        .a-presenter__bio{
            padding: 1% 5%;
            width: 50%;
            p{
                margin-bottom: 15px;
            }
            .subheading {
                color: #514a9d;
                font-size:30px;
            }
            .heading {
                margin-bottom: 30px;
                font-size: 40px;
                font-weight: 500;
            }
        }
    }
}