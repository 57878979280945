.l-hero {
    text-align: center;

    .title {
        color: $pallete-dark-grey;
        font-weight: 300;
    }

    &.has-banner {
        .title {
            color: #fff;
            font-weight: 300;
        }
    }

    @include m-respond-to('notebook') {
        &.has-banner {
            padding: 5rem 0;
        }
    }
}
