.gh-list {
    &__item {
        display: flex;
    }

    &__link {
        display: flex;
        width: 100%;
        font-weight: 500;
        transition: .2s;
    }

    &__icon {
        flex-shrink: 0;
        margin-right: 10px;
    }

    &__label {
        flex-grow: 1;
    }
}
