.gh-notice {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    color: #576669;
    background: #ffdb78;
    font-weight: 600;

    &__icon {
        flex-shrink: 0;
        flex-basis: 45px;
    }

    &__text {
        margin-left: 1rem;

        p {
            font-size: 1.1rem;
            line-height: 1.3;
        }
    }
}
