.gh-page--author {
    padding-bottom: 2rem;

    .gh-page__main {
        margin-bottom: 2rem;
    }

    .author__avatar {
        margin-bottom: 1rem;
    }

    .gh-card__image {
        background-size: contain;
    }

    .heading {
        color: #2c3138;
    }

    .author__name {
        margin-bottom: 1rem;
        font-size: 1.7rem;
    }

    .author__bio {
        padding: 1rem;
        background: #fff;

        .heading {
            margin-bottom: .5rem;
            font-size: 1.5rem;
        }
    }

    .author__details {
        margin-bottom: 1rem;

        .detail {
            + .detail {
                margin-top: 1rem;
            }

            &__heading {
                color: #595a60;
                font-weight: 600;
            }
        }
    }

    .author__socials {
        .social {
            display: flex;
            align-items: center;

            + .social {
                margin-top: .5rem;
            }

            &__icon {
                margin-right: .5rem;

                i {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    line-height: 30px;
                    text-align: center;
                }
            }
        }
    }

    .author__courses {
        padding: 1rem;
        background: #fff;
    }

    .widget__heading {
        margin-bottom: 1rem;
        font-size: 1.2rem;
    }

    .widget__footer {
        margin-top: 2rem;
    }

    .courses__listing {
        padding-left: 1rem;
        list-style-type: disc;
    }

    .course__link {
        line-height: 1.3;

        + .course__link {
            margin-top: .5rem;
        }
    }

    .course__buttons {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
    }

    @include m-respond-to('notebook') {
        .author {
            &__avatar {
                .gh-card__image {
                    height: 250px;
                }
            }
        }
    }
}