.gh-dashboard {
    .gh-fuzzy-search {
        margin-bottom: 1rem;

        .el-input__inner {
            background: #fff;
        }
    }

    .gh-loadmore {
        margin-top: 2rem;
        text-align: center;
    }
}
