.faqs {
    background: #fff;

    .gh-section__heading {
        margin: 0 0 30px;
        font-size: 40px;
        color: #514a9d;
        text-align: center;
    }

    .el-collapse-item__header {
        font-size:20px; color:#333;
    }

    p {
        font-size:18px;
        padding-top:30px;
        color: #a0a0a0;
    }

}