.mcd-footer {
    color: #fff;
    background: #2c3138;

    .a-logo {
        max-width: 200px;
        margin: 0 0 1rem;
    }

    .a-information {

        p {
            font-size: 18px;
        }

        + .a-information {
            margin-top: 1rem;
        }

        &.m--limit {
            max-width: 400px;
        }

        &.m--phone {
            display: flex;
            flex-flow: column;
        }

        &.m--cards {

            ul {
                display: flex;
                padding: 0;
                justify-content: space-around;
                list-style-type: none;
            }

            i {
                color: #565a60;
                font-size: 50px;
            }
        }
    }

    .u-slideup-link {
        color: #fff;
    }

    .a-copyright {
        margin-top: 1rem;
        color: #a0a0a0;
        font-size: 14px;

        a {
            color: #a0a0a0;
        }

    }

    @include m-respond-to('small') {
        text-align: center;

        .a-logo {
            margin: 1rem auto;
        }

        .a-information {
            &.m--limit {
                margin: 1rem auto;
            }
        }
    }

    @include m-respond-to('medium') {
        .a-information {
            justify-content: center;

            p {
                padding: 0 10px;
            }

            &.m--address {
                display: flex;
            }
        }
    }

    @include m-respond-to('medium-extra') {
        .a-information {
            &.m--phone {
                flex-flow: row;

                p {
                    display: flex;
                }
            }
        }
    }
}