.gh-dashboard--course-listing {
    .gh-card {
        padding: 1rem;

        + .gh-card {
            margin-top: 1rem;
        }

        &__image {
            margin: 0 0 .5rem;
        }

        &__actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: .5rem;

            > .gh-button,
            > span {
                flex-basis: 49%;
            }

            .gh-button {
                width: 100%;
            }
        }
    }

    .course {
        &__title {
            margin-bottom: .5rem;
            font-size: 1.2rem;
            line-height: 1.2;
        }
    }

    .load-more {
        margin-top: 2rem;
        text-align: center;
    }

    .meta {
        &__item {
            display: flex;
            align-items: center;
        }

        &__icon {
            flex-basis: 30px;
            flex-shrink: 0;
        }

        &__label {
            flex-basis: 75px;
            font-weight: 600;
        }
    }

    @include m-respond-to('extra-small') {
        .gh-card {
            display: flex;
            flex-flow: row wrap;

            &__image {
                flex-basis: 30%;
                margin: 0;
            }

            &__body {
                flex-basis: 70%;
                padding: 0 1rem;
            }

            &__actions {
                margin-top: 1rem;
                flex-grow: 1;
            }
        }
    }

    @include m-respond-to('tablet') {
        .gh-card {
            align-items: flex-start;

            &__image {
                flex-basis: 20%;
                height: 100px;
            }

            &__body {
                flex-grow: 1;
                flex-basis: 60%;
            }

            &__actions {
                flex-flow: column;
                flex-basis: auto;
                align-items: stretch;
                margin-top: 0;
            }
        }
    }

    @include m-respond-to('notebook') {
        .gh-card {
            &__body {
                flex-grow: 1;
            }

            &__actions {
                flex-basis: 15%;

                .gh-button {
                    flex-basis: auto;
                    margin-bottom: .5rem;
                    font-size: 14px;
                }
            }
        }
    }

    @include m-respond-to('medium') {
        .gh-card {
            flex-flow: row nowrap;

            &__body {
                flex-basis: 55%;
            }

            &__actions {
                flex-flow: row nowrap;

                > span {
                    flex-basis: 100%;
                    margin-right: .5rem;
                }

                .gh-button {
                    + .gh-button {
                        margin: 0 0 0 5px;
                    }
                }
            }
        }

        .course__meta {
            display: block;
        }

        .meta {
            &__item {
                display: inline-block;

                + .meta__item {
                    margin-left: 1rem;
                }
            }

            &__icon {
                margin-right: 2px;
            }

            &__label {
                margin-right: 2px;
            }
        }
    }
}
