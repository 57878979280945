.gh-mobile-menu {
    &__modal {
        width: 80%;
        max-width: 400px;

        .el-dialog__header,
        .el-dialog__body {
            padding: 0;
        }
    }

    .gh-scout {
        .el-input__inner {
            border: 0;
            color: #fff;
            background: #2b303b;
        }
    }

    &__indicator {
        padding: .5rem;
        background-color: #575b61;
        font-weight: 700;
        font-size: 1rem;
        text-transform: uppercase;

        .login-button {
            border: 0;
            color: #24c6dc;
            background: transparent;
            font-size: 1.2rem;
        }
    }

    &__items {
        background: #ededed;

        .gh-list {
            position: absolute;
            width: 100%;
            background: #ededed;

            &__item {
                display: block;
                padding: .5rem;
                border-top: 1px solid #fff;
                border-bottom: 1px solid #d8d7d7;
                color: #2c3138;
                font-weight: 600;
                font-size: 1rem;

                &.has-icon {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &::after {
                        display: block;
                        content: '\f054';
                        color: $brand-secondary;
                        font-weight: 800;
                        font-family: 'FontAwesome';
                    }
                }
            }

            &__link {
                display: block;
                color: #2c3138;

                &.is-highlighted {
                    color: $brand-secondary;
                    font-weight: 600;
                }
            }
        }
    }

    .gh-form {
        &--login {
            padding: 1rem;
        }

        &__actions {
            display: flex;

            .gh-button--cta {
                margin-right: 1rem;
            }

            .action-link {
                font-size: .8rem;
            }
        }
    }

    @include m-respond-to('notebook') {
        display: none;
    }
}
