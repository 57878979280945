.l-hero {
    background: #f9fced;
    background: linear-gradient(to right,  #f9fced 0%,#d3d2e0 100%);

    .hero__banner {
        padding: 15px;

        img {
            width: 100%;
            max-width: 450px;
            margin: 0 auto;
        }
    }

    .hero__details {
        padding: 15px 0;
        color: #fff;
        background: rgba(0, 0, 0, .65);
        text-align: center;

        p {
            color: #fff;
        }
    }

    .hero__info {
        &.is-last {
            padding: 15px 0;
        }
    }

    .a-features {
        display: flex;
        overflow: auto;

        .blurb {
            min-width: 200px;
            margin-right: 20px;
        }

        .blurb__icon {
            max-width: 90px;
            margin: 0 auto;
        }
    }

    @include m-respond-to('notebook') {
        background: url('../images/mcd/masterclass-header-image-fullsize.jpg') no-repeat center center;
        background-size: cover;

        .hero__banner {
            img {
                max-width: 300px;
                margin: 0;
            }
        }
    }

    @include m-respond-to('medium') {
        .hero__banner {
            padding: 80px 0;
        }

        .hero__info {
            text-align: left;
        }

        .hero__details {
            padding: 20px 0;

            > .container {
                display: flex;
                align-items: center;

                &::before,
                &::after {
                    display: none;
                }
            }

            .a-features {
                flex-basis: 50%;
                flex-shrink: 0;
                justify-content: center;
            }

            .blurb {
                min-width: auto;
                margin-right: 0;
                padding: 0 10px;
                cursor: pointer;

                .tooltip-inner {
                    color: #2c3138;
                    background: #fff;
                    font-weight: 700;
                }

                .tooltip-arrow {
                    border-top-color: #fff;
                }

                &:hover {
                    path {
                        fill: #fff;
                    }
                }
            }

            .blurb__description {
                display: none;
            }
        }
    }
}

.el-tooltip__popper {
    &.mcd-tooltip {
        color: #1f2d3d;
        background: #fff;
        font-weight: 600;
    }

    .blurb__description {
        p {
            font-size: 14px;
        }
    }
}