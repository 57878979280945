.gh-sidebar {
    &__trigger {
        position: fixed;
        top: 1rem;
        left: 1rem;
        z-index: 10;
        display: flex;
        visibility: hidden;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 2px;
        color: #fff;
        background: rgba($brand-primary, .9);
        opacity: 0;
        font-weight: 600;
        font-size: 1rem;
        transition: visibility .5s, opacity .5s;

        &.is-active {
            visibility: visible;
            opacity: 1;
        }
    }

    &__wrapper {
        color: $pallete-dark-grey;
        background: #fff;

        &.is-mobile {
            display: none;

            .gh-sidebar__container {
                @include m-absolute();

                position: relative;
                overflow-x: hidden;
                overflow-y: auto;
                width: 100%;
                max-width: 320px;
                height: 90%;
                max-height: 650px;
            }

            .gh-sidebar__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 18px;
                color: $brand-secondary;
                background: #fff;
                font-weight: 500;
                font-size: 1.2rem;

                .label {
                    font-size: 1.2rem;
                }
            }
        }

        &.is-visible {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: get-value-of($z-index, 'filters-modal');
            display: block;
            width: 100%;
            background: rgba(0, 0, 0, .8);
        }
    }
    
    &__menu {
        background: #fff;
        border-right: 0;
    }
}

//==================================================
//                 [-- COMMON MENU --]
//==================================================
.gh-sidebar__menu {
    .el-menu-item {
        height: auto;
        padding: 0 !important;
        line-height: 1;
        transition: .2s;

        a {
            display: flex;
            align-items: center;
            padding: 10px;
            color: #2f3138;
            font-weight: 500;
            font-size: 1rem;
        }

        &.is-purple {
            border-left: 5px solid $brand-secondary;
        }

        &.is-teal {
            border-left: 5px solid #085078;
        }

        &.is-active {
            border-left-width: 15px;

            .gh-menu__link {
                background: #d1dbe5;
                font-weight: 600;
            }
        }
    }

    .el-menu-item-group__title {
        display: none;
    }
}

//==================================================
//                 [-- SUBMENU --]
//==================================================
.gh-submenu {
    transition: .2s;

    &.is-cyan {
        border-left: 5px solid $brand-primary;
    }

    &.is-purple {
        border-left: 5px solid $brand-secondary;
    }

    &.is-teal {
        border-left: 5px solid #085078;
    }

    &.is-active {
        border-left-width: 15px;
    }

    .el-menu-item {
        a {
            padding: 1rem 10px 1rem 55px;
            font-weight: 400;
        }
    }

    .el-submenu__title {
        height: auto;
        padding: 10px !important;
        line-height: 1;

        &:hover {
            background: transparent;
        }
    }
}

//==================================================
//                 [-- MENU --]
//==================================================
.gh-menu {
    &__item {
        display: flex;
        align-items: center;
        font-weight: 500;
    }

    &__icon {
        display: inline-block;
        flex-basis: 30px;
        margin-right: 1rem;
    }

    &__label {
        font-size: 1.1rem;
        flex-grow: 1;

        .is-cyan & {
            color: $brand-primary;
        }

        .is-teal & {
            color: #085078;
        }

        .is-purple & {
            color: $brand-secondary;
        }
    }

    &__right {
        flex-basis: 40px;
    }
}

@include m-respond-to('notebook') {
    .gh-sidebar {
        &__wrapper,
        &__menu {
            background: transparent;
        }

        &__menu {
            > li {
                background: #fff;

                + li {
                    margin-top: 10px;
                }
            }
        }
    }

    .gh-submenu {
        .el-menu {
            background: #fff;
        }
    }
}
