.l-event {
    background: #fff;

    .gh-section__heading {
        margin-bottom: 5px;
        color: #2a3038;
        font-weight: 500;
        font-size: 30px;
        text-align: left;
    }

    .gh-section__subheading {
        margin-bottom: 25px;
        color: $brand-secondary;
        font-weight: 500;
        font-size: 40px;
    }

    .event__summary {
        //margin-bottom: 2rem;

        .heading {
            color: $brand-secondary;
        }
    }

    .event__info {
        max-width: 450px;
        margin: 0 auto;
        margin-top: 20px;
        .gh-card {
            height: auto;
        }

        .a-field {
            margin-bottom: 1rem;

            p {
                color: #565a60;
                font-size: 1.5rem;
            }
        }

        .a-field-last {
            margin-bottom: 0;
        }

        .a-field__label {
            margin: 0;
            font-weight: 300;
            font-size: 1.2rem;
            font-weight: 300; font-size: 20px;
        }

        .gh-card {
            background: #e5e5e5;
            padding: 15px;
            border-radius: 5px;

            .gh-button {
                width: 100%;
                padding: 10px;
                border: 0;
                border-radius: 5px;
                color: #fff;
                background: #24c6dc;
                font-size: 20px;
                text-align: center;
                -webkit-transition: .3s;
                transition: .3s;
                font-weight: 700;
            }
        }
    }

    @include m-respond-to('tablet') {
        .event {
            &__container {
                //display: flex;
                justify-content: space-between;
            }

            &__summary {
                flex-grow: 1;
                padding: 0 3rem;
            }

            &__info {
                flex-basis: 400px;
                flex-shrink: 0;

                .gh-card__body,
                .gh-card__footer {
                    padding: 1rem;
                }
            }
        }
    }

    @include m-respond-to('notebook') {
        .event {
            &__container {
                display: flex;
                justify-content: space-between;
            }
        }
        .gh-section__subheading {
            font-size: 2.5rem;
        }
    }
}