.gh-modal {
    border-radius: 0;

    .el-button {
        border: 0;
        border-radius: 0;
        transition: .2s;

        &--default {
            color: $brand-secondary;
            background: #f3f3f3;
            font-weight: 600;
            font-size: 1rem;

            &:hover {
                color: $brand-secondary;
                background: #e0e0e0;
            }
        }

        &--primary {
            color: #fff;
            background: #24c6dc;

            &:hover {
                color: #fff;
                background: #1CA0B7 ;
            }
        }
    }
}
