.gh-newsletter {
    @extend %gradient-venice-blue;

    

    &__header {
        display: flex;
        margin-bottom: 1rem;

        .icon {
            display: none;
            flex-shrink: 0;

            img {
                vertical-align: middle;
            }
        }

        .divider {
            display: none;
        }

        .heading {
            font-weight: 600;
            line-height: 1.2;
            font-size: 1.1rem;
        }
    }

    .gh-form--newsletter {
        max-width: 915px;
        margin-left: .7rem;
    }

    .el-form-item__error {
        color: #fff;
    }

    .el-input__inner {
        padding: 1.5rem 1rem;
        border-radius: 2px;
        border-color: #fff;
        color: $text-color;
        background: #fff;
        font-size: 1.2rem;
    }

    ::placeholder {
        color: #808080;
        font-size: 1.1rem;
    }

    .gh-button--submit {
        padding: 1rem 2.4rem;
        border: 1px solid #fff;
        color: #fff;
        background: 0 0;
    }

   


    @include m-respond-to('tablet') {
        .gh-newsletter__header {
            align-items: center;

            .heading {
                font-size: 1.2rem;
            }

            .icon {
                display: block;
                max-width: 54px;
            }

            .divider {
                display: block;
                min-width: 1px;
                height: 70px;
                margin: 0 1rem;
                background: #fff;
            }
        }

        .gh-form__content {
            display: flex;
            justify-content: space-between;
        }

        .gh-form__group {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
            max-width: 755px;
        }

        .el-form-item {
            margin-bottom: 5px;
            // flex-basis: 32.2%;
        }

        .gh-form__actions {
            margin-left: .5rem;
        }

        .gh-button--submit {
            height: 152px;
            line-height: 0;
        }
    }

    @media(min-width:1023px) {
        .gh-button--submit {
            height: 47px;
            line-height: 0;
        }
    }

    @include m-respond-to('notebook') {
        .gh-newsletter__header {
            margin-bottom: 0;
        }
    }
}
