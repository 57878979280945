.gh-page--terms {
    padding-bottom: 2rem;

    .term {
        + .term {
            margin-top: 1.5rem;
        }

        &__heading {
            margin-bottom: .5rem;
            font-size: 1.3rem;
        }

        &__subheading {
            margin-bottom: .5rem;
            font-size: 1.1rem;
            font-style: italic;
        }

        p {
            font-size: 1.1rem;
            + p {
                margin-top: .5rem;
            }
        }

        a {
            font-family: freight-sans-pro, sans-serif;
        }

        ul {
            margin: .5rem 0;
            padding-left: 2rem;
            list-style: disc;

            &.address {
                font-weight: 600;
                list-style: none;
            }
        }

        ol {
            margin: .5rem 0;
            padding-left: 2rem;
            list-style: lower-alpha;
        }

        li {
            font-size: 1.1rem;
            line-height: 1.4;
            + li  {
                margin-top: .5rem;
            }
        }
    }
}
