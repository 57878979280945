.gh-page--about {
    .gh-section {
        color: #555a60;

        &__heading {
            margin-bottom: 1rem;
        }

        &__subheading {
            margin-bottom: 1rem;
            color: #2a3038;
            font-size: 1.8rem;
            text-align: center;
        }

        &__body {
            max-width: 810px;
        }
    }

    .gh-page-intro {
        .gh-section__subheading {
            text-align: left;
            max-width: 770px !important;
        }
    }

    .gh-page-menus {
        padding: 0;
        background: white;
        border-bottom: solid 2px #e6e6e6;
        .container {
            padding: 0;
        }
        .el-menu {
            border: none;
            display: flex;
            justify-content: space-between;

            .el-menu-item {
                color: #5D6067;
                font-size: 1.05rem;
                padding: 0 3rem;
            }

            .el-menu-item.is-active {
                color: #085078;
                font-weight: 600;
                border-bottom: 4px solid #E5E5E5;
            }
        }
    }

    .intro {
        padding-bottom: 2rem;

        &__text {
            margin: 0 auto;
            color: #2a3038;
            font-weight: 600;
            font-size: 1.5rem;
            text-align: center;
        }
    }
    
    .mission {
        background: #fff;

        &__content {
            margin: 0 auto;
            text-align: center;
        }

        &__text {
            color: #2a3038;
            font-size: 1.4rem;
            text-align: center;

            .quoted {
                position: relative;
                display: inline-block;
            }
        }

        &__quote {
            position: absolute;
            display: none;
            width: 50px;

            &--left {
                top: -50px;
                left: -50px;
            }

            &--right {
                top: 5px;
                right: -60px;
            }
        }
    }

    .control {
        color: #555a60;
        background: #f4f5f6;

        &__image {
            display: none;
        }

        &__accordion {
            margin-top: 2rem;
        }

        .el-collapse {
            border: 0;

            &-item {
                &.is-active {
                    .accordion__icon {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .el-collapse-item__header {
            display: flex;
            align-items: center;
            height: auto;
            padding: 1rem;
            border: 0;
            background: transparent;
            line-height: 1;
        }

        .el-collapse-item__wrap {
            border-bottom: 0;
            background: transparent;
        }

        .el-collapse-item__header__arrow {
            display: none;
        }

        .el-collapse-item__content {
            font-size: 1rem;
        }

        .accordion {
            &__icon {
                max-width: 30px;
                transition: transform .2s;
            }

            &__heading {
                flex-grow: 1;
                margin-left: 1rem;
                font-weight: 700;
                font-size: 1.2rem;
            }
        }
    }

    .learning-modes {
        color: #555a60;
        background: #fff;

        &__listing {
            margin-top: 2rem;
        }

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }

        .lm {
            text-align: center;

            + .lm {
                margin-top: 2rem;
            }
            
            &__body {
                text-align: left;
            }

            &__icon {
                max-width: 80px;
                margin: 0 auto;
            }

            &__heading {
                margin-bottom: .5rem;
                font-weight: 700;
                font-size: 1.5rem;
            }
        }
    }

    .experts {
        color: #555a60;
        background: #e5e5e5;

        &__content {
            margin-top: 2rem;
        }

        &__heading {
            margin-bottom: .5rem;
            font-weight: 600;
            font-size: 1.3rem;
            line-height: 1;
        }

        [class^='col-'] {
            margin-bottom: 1rem;
        }

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }
    }

    .impact {
        background: #f4f5f6;

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }

        &__content {
            margin-top: 1rem;

            p {
                + p {
                    margin-top: .5rem;
                }
            }
        }
    }

    .reasons {
        background: #fff;
        text-align: center;

        &__stats {
            margin-top: 2rem;
        }

        .stat {
            margin-bottom: 1rem;
            color: $brand-primary;

            &__number {
                font-weight: 700;
                font-size: 3rem;
            }

            &__text {
                font-weight: 600;
                font-size: 1.2rem;
            }
        }

        &__details {
            .heading {
                font-weight: 700;
                font-size: 1.2rem;
            }
        }

        .details {
            margin: 2rem 0;
        }

        .detail {
            margin-bottom: 1rem;
            text-align: center;

            &__icon {
                max-width: 80px;
                margin: 0 auto;
            }

            &__content {
                margin-top: .5rem;
            }

            &__heading {
                font-weight: 700;
                font-size: 1.2rem;
            }
        }
    }
    
    .testimonials {
        background: #e5e5e5;

        .gh-section__subheading {
            margin-bottom: 2rem;
        }

        .testimonial {
            width: 100%;

            &__content {
                padding: 1rem;
                border-bottom: 5px solid;
                background: #fff;
            }
        }

        &__cta {
            margin-top: 2rem;
            text-align: center;
        }
    }

    .start {
        text-align: center;

        &__cta{
            margin: 2rem 0;
        }
    }

    @include m-respond-to('tablet') {
        .learning-modes {
            &__listing {
                display: flex;
                flex-flow: row wrap;
            }

            .lm {
                flex-basis: 50%;
                margin-bottom: 1rem;
                padding: 1rem;

                + .lm {
                    margin-top: 0;
                }
            }
        }

        .reasons__stats {
            display: flex;
            flex-flow: row wrap;
        }

        .stat {
            max-width: 33.33%;
            flex-basis: 33.33%;
            padding: 1rem;
        }

        .testimonials {
            .testimonial {
                max-width: calc(100% / 3 - 16px * 2);
            }
        }
    }

    @include m-respond-to('notebook') {
        .mission {
            &__quote {
                display: block;
            }
        }

        .control {
            overflow: hidden;

            .gh-section__subheading {
                text-align: left;
            }

            &__container {
                position: relative;
                display: flex;
            }

            &__content {
                flex-basis: 60%;
                z-index: 1;
            }

            &__image {
                position: absolute;
                top: -50px;
                left: 50%;
                display: flex;
                width: 580px;
                height: 580px;
                border-radius: 50%;
                background: #f9f9fa;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    max-width: 300px;
                    margin: 0 auto;
                    transform: translate3d(-50%, -50%, 0);
                }
            }

            .el-collapse-item__header {
                padding: .5rem 0;
            }

            .el-collapse-item__wrap {
                padding-left: 2rem;
            }
        }

        .learning-modes {
            .gh-section__subheading {
                text-align: left;
            }

            .lm {
                display: flex;
                align-items: flex-start;
                margin-bottom: 2rem;
                padding: 0;

                &__icon {
                    flex-basis: 70px;
                    flex-shrink: 0;
                }

                &__content {
                    padding: 0 1rem;
                    text-align: left;
                }
            }
            
            &__cta {
                margin-top: 0;
            }
        }
        
        .experts {
            .gh-section__subheading {
                text-align: left;
            }

            .col-md-6 {
                padding-right: 2rem;
            }
        }

        .impact {
            padding: 0;
            background: radial-gradient(circle at right, transparent 40%, rgba(244, 246, 246, 1) 50%), url('/images/app/full-social-impact-image.jpg');
            background-position: center center, right center;
            background-size: contain, 700px;
            background-repeat: no-repeat;

            &__wrapper {
                max-width: 50%;
                padding: 2rem 0;
            }

            .gh-section__subheading,
            &__cta {
                text-align: left;
            }
        }

        .reasons {
            &__stats {
                margin-top: 1rem;
            }

            .stat {
                margin-bottom: 2rem;

                &__number {
                    font-size: 5rem;
                }
            }

            .heading {
                max-width: 60%;
                margin: 0 auto;
            }

            .detail {
                display: flex;
                align-items: flex-start;
                margin-bottom: 2rem;

                &__icon {
                    flex-basis: 70px;
                    flex-shrink: 0;
                    margin: 0
                }

                &__content {
                    margin: 0;
                    padding: 0 1rem;
                    text-align: left;
                }
            }
        }
    }

    @include m-respond-to('medium') {
        .impact {
            padding: 0;
            background: radial-gradient(circle at right, transparent 25%, #f4f6f6 35%), url("/images/app/full-social-impact-image.jpg");
            background-position: center center, right center;
            background-size: contain, 700px;
            background-repeat: no-repeat;
        }
    }
}