* {
    box-sizing: border-box;
}

html {
    font-size: 15px;
}

body {
    position: relative;
    margin: 0;
    font-family: freight-sans-pro, sans-serif;
    font-weight: 500;
    -webkit-text-size-adjust: 100%;
}

[v-cloak] {
    display: none;
}

p {
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    color: $brand-secondary;
    font-family: proxima-nova, sans-serif;
    text-decoration: none;

    &:hover,
    &:focus,
    &:visited {
        text-decoration: none;
    }
}

.gh-font__proxima-nova {
    font-family: proxima-nova, sans-serif;
}

.gh-font__freight-sans-pro {
    font-family: freight-sans-pro, sans-serif;
}

b,
strong {
    font-weight: 600;
}

img {
    display: block;
    width: 100%;
}

.gh-bold {
    font-weight: 600 !important;
}

button {
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    &:focus {
        outline: none;
    }
}

.gh-page-breadcrumb {
    background: white;
}

.container {
    padding: 20px;
}


.gh-bg-white {
    background-color: white;
}

.gh-text-align-left {
    text-align: left !important;
}

.gh-text-center {
    text-align: center !important;
}

.grecaptcha-badge {
    z-index: 1000;
}

@media screen and (min-width: 48em) {
    .container {
        width: 48rem;
    }
}

@media only screen and (min-width: 64em) {
    .container {
        width: 64rem;
    }
}

@media only screen and (min-width: 75em) {
    .container {
        width: 76rem;
    }
}

.section {
    padding: 3rem 0;

    &.is-first {
        padding-top: 0;
    }
}

.title {
    font-size: 3rem;
    text-align: center;
}

.gh-full-width {
    width: 100%;
}

.fa {
    &-facebook {
        color: #fff;
        background-color: #3b5999;
    }

    &-twitter {
        color: #fff;
        background-color: #54abed;
    }

    &-google-plus {
        color: #fff;
        background-color: #db4f40;
    }

    &-linkedin {
        color: #fff;
        background-color: #007aba;
    }
}
