.gh-page--social-impact {
    text-align: left;

    .gh-section {
        color: #555a60;

        &__heading {
            margin-bottom: 2rem;
            text-align: left;
        }

        &__subheading {
            margin-bottom: 2rem;
            color: #2a3038;
            font-size: 1.3rem;
        }
    }

    .gh-page-intro {
        .gh-section__subheading {
            margin-bottom: 0;
        }
    }

    .leading {
        &__sub {
            margin-bottom: .5rem;
            color: #565a60;
            font-size: 1.2rem;
        }

        p {
            font-size: 1.3rem;
            font-weight: 500;
            font-size: 1.2rem;
        }

        .gh-body {
            display: flex;
            justify-content: start;
            flex-flow: column;
            &__left {
                max-width: 991px;
                flex-basis: 58%;
                line-height: 1.4;
                color: $pallete-dark-grey;
            }
            &__right {
                margin-top: 1rem;
                font-family: proxima-nova, sans-serif;
                font-size: 2.5rem;
                font-weight: 100;
                color: $pallete-dark-blue;
                flex-basis: 33%;
                line-height: 1.2;
            }
        }
        .gh-supported {
            margin-top: 2rem;
            max-width: 1074px;
            .gh-heading {
                color: $pallete-dark-grey;
                font-size: 1.2rem;
                font-weight: 600;
            }

            &__inner {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                img {
                    width: 110px;
                    height: 110px;
                }
                a {
                    cursor: pointer;
                }
                &__support {
                    width: 215px;
                    height: 200px;
                    background: #E5E5E5;
                    padding: 1rem;
                    border-radius: 4px;
                    margin-top: 1rem;
                    margin-right: 1rem;
                    box-shadow: -5px 1px 23px -4px rgba(0,0,0,0.2);
                    &__inner {
                        display: flex;
                        flex-flow: row;
                    }
                    &__id {
                        font-family: proxima-nova, sans-serif;
                        font-size: 3rem;
                        font-weight: 600;
                        line-height: 1;
                        margin-top: -.2rem;
                    }
                    &__title {
                        font-size: 1.1rem;
                        line-height: 1;
                        margin-left: 1rem;
                        font-weight: 600;
                    }
                    &__description p {
                        font-size: 1.1rem;
                        line-height: 1.3;
                        a {
                            color: white;
                            text-decoration: underline;
                        }
                    }
                    &__image {
                        display: flex;
                        justify-content: center;
                    }
                }
            }


        }
    }

    .content {
        p {
            + p {
                margin-top: .5rem;
            }
        }
    }

    .local-impact {
        background: #fff;

        &__content {
            margin-top: 1rem;
        }
    }

    .global-impact {
        .widgets,
        .fund {
            margin-top: 2rem;
        }

        iframe {
            width: 100%;
        }

        .gh-body__right {
            text-align: center;
        }

        .ng-scope {
            background: transparent !important;
        }

        .leading .gh-body {
            &__right {
                border-left: none;
            }
            &__left {
                p {
                   + p {
                        margin-top: .5rem;
                    }
                }
            }
        }
    }

    .gh-start {
        background: #fff;
        text-align: center;

        .gh-section__subheading {
            font-size: 2.15rem;
            font-weight: 600;
        }

        .gh-section__body {
            font-size: 1.3rem;
            max-width: 897px;
            margin: auto;

            a {
                color: $brand-primary;
            }
        }

        &__cta{
            margin-top: 3rem;
            margin-bottom: 2.5rem;
            .gh-button-redesign {
                color: $pallete-dark-blue;
                border-color: $pallete-dark-blue;
                padding: .8rem 3rem;
            }
        }
    }

    @include m-respond-to('small') {
        .global-impact {
            iframe {
                width: 335px;
            }
        }

        .leading .gh-supported__inner {
            justify-content: center;
        }
    }

    @include m-respond-to('tablet') {
        .leading .gh-supported__inner {
            a {
                color: #5D6067;
            }
            justify-content: start;

            &__support {
                width: 210px;
                height: 200px;

                &__title {
                    font-size: 1.2rem;
                }
            }
        }
        .global-impact {
            .leading .gh-body {
                &__right {
                    flex-basis: 38%;
                }
            }
        }
    }

    @include m-respond-to('notebook') {
        .leading .gh-supported__inner {
            &__support {
                width: 215px;
            }
        }

        .leading .gh-body {
            justify-content: space-between;
            flex-flow: row;
            &__right {
                margin-top: 0;
                padding-left: 1rem;
                border-left: solid 1px $pallete-dark-blue;
            }
        }
        .global-impact {
            .widgets {
                display: flex;
                justify-content: space-between;
            }

            .widget {
                padding: 0 1rem;

                &.map {
                    flex-grow: 1;
                }

                &.fund {
                    margin-top: 0;
                }
            }
        }
    }

    @include m-respond-to('medium') {
        .leading .gh-supported__inner {
            .support {
                margin-right: 1.5rem;
            }
            &__support {
                margin-right: 0;
            }
        }

        .global-impact {
            .leading .gh-body {
                &__right {
                    flex-basis: 33%;
                }
            }
        }
    }
}