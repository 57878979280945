.gh-dashboard--invoices {
    .gh-member-invoices {
        .el-table__header {
            th {
                padding: 1rem 0;
                font-weight: 600;
            }
        }

        .el-table__row {
            td {
                padding: 1rem 0;
                font-weight: 600;
            }
        }

        .el-table__expanded-cell {
            padding: 1rem;

            p {
                + p {
                    margin-top: .5rem;
                }
            }
        }

        .invoice {
            &__description {
                word-break: normal;
            }
        }
    }

    .gh-pagination {
        margin-top: 2rem;
        text-align: center;
    }
}