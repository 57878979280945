.gh-sort {
    &__label {
        margin-right: 1rem;
        font-weight: 700;
        font-size: 1rem;
    }

    &__selected {
        font-weight: 600;
        font-size: 1rem;

        .selected {
            &__label {
                margin-right: .5rem;
                color: $brand-secondary;
            }
        }
    }
}
