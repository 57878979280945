.registration {
    @extend %gradient-venice-blue;

    padding: 2rem 0;
    color: #fff;

    .gh-section__heading {
        margin: 0 0 30px;
        color: inherit;
        font-size: 40px;
    }

    .explainations {
        padding-left: 1rem;
        font-size: 16px;
        list-style: disc;

        li {
            + li {
                margin-top: .5rem;
            }

            strong {
                font-weight: 700;
            }
        }
    }

    .membership-message {
        max-width: 640px;
        margin: 2rem auto 0;
        padding: 1rem;
        background: rgba(255, 255, 255, .6);
        text-align: center;

        p {
            color: $brand-secondary;
            font-weight: 400;
            font-size: 18px;
        }
    }

    .selections {
        margin-top: 2rem;
    }

    .rego {
        position: relative;
        padding: 20px;
        border-radius: 5px;
        color: #2c3138;
        background: #fff;

        + .rego {
            margin-top: 2rem;
        }

        &.is-disabled {
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                content: '';
                background: rgba(0, 0, 0, 0.4);
            }
        }
    }

    .rego__title {
        margin: 0 0 20px;
        color: $brand-secondary;
        font-weight: 300;
        font-size: 2rem;
        text-align: center;

        > span {
            display: block;
        }

        .subtitle {
            display: block;
            font-weight: 400;
            font-size: 1.5rem;
        }
    }

    .gh-list {
        margin-top: 1rem;
    }

    .gh-list__item {
        display: flex;
        align-items: baseline;
        margin-bottom: 5px;
        color: #2c3138;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;

        + .gh-list__item {
            margin-top: 1rem;
        }
    }

    .rego__body {
        font-weight: 600;

        p {
            color: #2c3138;
            font-weight: 600;
            font-size: 16px;
        }
    }

    .rego__amount {
        margin: 0 0 20px;
        color: #24c6dc;
        font-weight: 300;
        font-size: 34px;
        text-align: center;

        .is-smaller {
            font-weight: 500;
            font-size: 1rem;
        }

        .amount {
            font-weight: 500;
            font-size: 20px;
        }
    }

    .rego__amount.free {
        font-size: 34px;
    }

    .rego__footer {
        margin-top: 2rem;
        text-align: center;

        .gh-button {
            width: 80%;
            margin: 0 auto;
            border-radius: 5px;
            font-size: 20px;
        }
    }

    .rego__login {
        width: 90%;
        max-width: 480px;
    }

    .gh-form__response {
        margin-bottom: 1rem;
    }

    @include m-respond-to('notebook') {
        .selections {
            display: flex;
            justify-content: space-between;
        }

        .rego {
            display: flex;
            flex-flow: row wrap;
            max-width: 32%;
            flex-basis: 32%;

            + .rego {
                margin-top: 0;
            }
        }

        .rego__footer {
            align-self: flex-end;
            flex-grow: 1;
        }
    }

    @include m-respond-to('medium') {
        .explainations {
            font-size: 18px;
        }

        .rego {
            display: block;
        }

        .rego__header {
            //min-height: 120px;
        }

        .rego__title {
            font-size: 30px;
            margin-bottom: 30px;
        }

        .rego__amount {
            margin-bottom: 30px;
        }

        .rego__body {
            min-height: 310px;
        }

        .registration {
            .gh-list__item {
                font-size: 18px;
            }
        }
    }
}

.registration-completion {
    padding: 2rem 0;

    .gh-section__heading {
        font-size: 2rem;
    }

    p {
        + p {
            margin-top: 1rem;
        }
    }
}

.registration-form {
    padding: 2rem 0;

    .mcd-rego {
        margin-top: 2rem;
    }

    .membership-message {
        margin: 0 auto 2rem;
        padding: 1rem;
        border: 1px solid #5ea5c8;
        background: #d0e5ef;
        text-align: center;

        p {
            color: #31708f;
            font-weight: 500;
            font-size: 1.2rem;
        }
    }
}

.mcd-rego {
    .gh-section__heading {
        color: #24c6dc;
        font-size: 28px;

        span {
            display: block;
        }

        em {
            font-size: 18px;
        }
    }
}