.gh-dashboard--overview {
    .quick-links {
        margin: 1.5rem 0;

        .label {
            margin-bottom: 1rem;
            color: $brand-secondary;
            font-weight: 600;
            font-size: 1.2rem;
        }
    }

    .gh-list {
        padding-left: 1rem;
        border-left: 2px solid #ccc;

        &__link {
            padding: 5px 0;
            color: #565a60;
            font-weight: 600;

            &:hover {
                color: $brand-primary;
            }
        }
    }

    .gh-notice {
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: 2rem;

        &__icon {
            display: none;
        }

        &__text {
            margin-left: 0;
        }

        &__actions {
            flex-basis: 100%;
            margin-top: .5rem;
            text-align: center;
        }
    }

    @include m-respond-to('small') {
        .gh-notice {
            margin-bottom: 2rem;
            flex-flow: row;

            &__icon {
                display: block;
            }

            &__text {
                flex-basis: 80%;
                margin-left: 1rem;
                padding: 0 20px 0 10px;
            }

            &__actions {
                flex-basis: auto;
                margin-top: 0;
            }
        }
    }
}
