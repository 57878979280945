.gh-page--contact {
    .gh-section__heading {
        text-align: left;
    }

    .contact-form {
        margin: 6rem 0 0;
    }

    .gh-page-intro {
        padding-bottom: 1rem !important;
    }

    .actions {
       padding-top: 0;
       margin-top: -20px;
    }

    .el-collapse-item__header p {
        font-size: 1.1rem;
        font-family: proxima-nova, sans-serif;
    }

    .el-collapse-item__content p {
        font-size: 1.1rem;
        font-family: proxima-nova, sans-serif;
    }

    .more-information {
        background: #fff;
        font-weight: 500;

        .contact-details {
            display: flex;
            margin-bottom: 2rem;
        }

        .gh-section__heading {
            margin-bottom: 0;
        }

        .icon {
            flex-basis: 80px;
            flex-shrink: 0;
            margin-right: 1rem;
        }

        .gh-list__link {
            display: block;
            color: $pallete-dark-grey;
            transition: .3s;

            &:hover {
                color: $brand-primary;
            }
        }
    }

    .gh-faqs {
        &__selector {
            margin-bottom: 2rem;
        }
    }

    @include m-respond-to('tablet') {
        .contact-form {
            margin-top: 0;
        }

        .actions {
            [class^=col-] {
                padding: 0 .5rem;
            }
        }

        .contact-details {
            &.is-right {
                justify-content: flex-end;
            }
        }
    }
}
