.gh-page--registration {
    .gh-page__heading {
        margin-bottom: 2rem;
        color: $brand-primary;
    }

    .package {
        padding: 1rem;
        background: #fff;

        &__heading {
            margin-bottom: 1rem;
            color: $brand-secondary;
            font-weight: 600;
            font-size: 1.3rem;
        }
    }

    .package__features {
        .feature {
            display: flex;
            align-items: flex-start;
            padding: .5rem;

            &__icon {
                flex-basis: 20px;
                flex-shrink: 0;
                margin-right: 10px;
                font-size: 18px;
            }

            &__label {
                font-weight: 500;
                line-height: 1.5;
            }
        }
    }
    
    .gh-form--registration {
        margin-bottom: 4rem;

        .gh-form__section {
            padding: 1rem 0;

            + .gh-form__section {
                border-top: 2px solid $brand-primary;
            }
        }

        .gh-form__heading {
            margin-bottom: 1rem;
            font-size: 1.3rem;
        }

        .el-input__inner {
            background: #e5e5e5;
        }

        .price {
            color: $brand-secondary;
            font-weight: 600;
            font-size: 1.5rem;
        }

        .payment-frequency {
            .gh-form__label {
                margin-bottom: 1rem;
                font-size: 1.1rem;
            }

            .el-radio {
                + .el-radio {
                    margin-top: .5rem;
                    margin-left: 0;
                }
            }

            .el-radio__label {
                font-weight: 500;
            }
        }

        .tnc {
            .el-checkbox {
                display: flex;
                align-items: baseline;

                &__label {
                    white-space: normal;
                    line-height: 1.5;
                }
            }
        }
    }

    @include m-respond-to('small') {
        .gh-form--registration {
            .payment-frequency {
                .el-radio {
                    align-items: center;
                }
            }
        }
    }

    @include m-respond-to('tablet') {
        .gh-form--registration {
            .gh-form__group {
                display: flex;
                justify-content: space-between;
            }

            .el-form-item {
                flex-basis: 49%;
            }
        }
    }

    @include m-respond-to('notebook') {
        .gh-form--registration {
            padding: 0 1rem;
        }
    }
}

.gh-page--registration-completed {
    padding: 2rem 0;

    .actions {
        margin-top: 2rem;
        text-align: center;
    }

    .gh-button {
        font-size: 1.2rem;
    }
    
    .gh-page__tagline {
        margin-bottom: 2rem;
    }
    
    .gh-section {
        padding: 0;
        
        + .gh-section {
            margin-top: 2rem;
        }
        
        &__heading {
            margin-bottom: .5rem;
            font-weight: 600;
            font-size: 1.2rem;
            text-align: left;
        }

        p {
            + p {
                margin-top: .5rem;
            }
        }
    }
}
