.gh-page--course {
    .gh-course {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }

    .back-to-catalogue {
        color: $brand-secondary;
        font-weight: 600;
    }

    .course-title {
        margin-bottom: 1.5rem;
        color: $pallete-dark-grey;
        font-weight: 700;
        font-size: 2.5rem;
        text-align: left;
    }

    .course-details {
        margin: 2rem 0;
        color: #5D6067;
        font-weight: 500;
        display: flex;

    }

    .detail {
        + .detail {
            margin-top: 2rem;
        }

        &__heading {
            margin-bottom: 1rem;
            color: #2c3138;
            font-weight: 700;
            font-size: 1.5rem;
        }

        &__content {
            + .detail__content {
                margin-top: 1rem;
            }

            ul {
                margin: 1rem 0;
                padding-left: 2rem;
                list-style: disc;

                ul {
                    list-style: circle;
                }
            }

            ol {
                margin: 0;
                padding-left: 2rem;
            }

            li {
                + li {
                    margin-top: 1rem;
                }
            }

            span {
                color: inherit !important;
                font-weight: inherit !important;
                font-size: 1rem !important;
                font-family: inherit !important;
            }

            p {
                + p {
                    margin-top: 1rem;
                }
            }
        }
    }

    .gh-course-listing {
        margin-left: -.5rem;
        margin-right: -.5rem;
        margin-top: 1rem;
        &__info {
            font-weight: 500;
            font-size: 1rem;
            color: #868a8f;
        }
        &__results {
            display: flex;
            flex-flow: row wrap;
            margin-bottom: 2rem;
        }

        &__item {
            display: flex;
            flex-grow: 1;
            padding: .5rem;

            .gh-card--program {
                width: 100%;
            }

            &.flip-move {
                transition: transform .6s;
            }
        }
    }

    .gh-related-courses {
        padding-top: 1rem;
        .row {
            margin-top: 1rem;
            margin-left: 0;
            > [class^=col] {
                margin-bottom: 1.5rem;
            }
            .col-md-3 {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .detail__heading {
            margin-bottom: 1.54rem;
        }
        .gh-card--program {
            box-shadow: -5px 4px 23px 0px rgba(236, 236, 237, 1);
            figure {
                background-size: cover;
            }
            .program__title {
                margin-bottom: 2rem;
                max-width: 205px;
            }
        }
        .gh-view-more {
            text-align: center;
            margin-top: 2rem;
            margin-bottom: 3rem;
            a {
                color: $pallete-dark-grey;
            }
            .gh-button-redesign {
                padding: 1rem 2.7rem;
            }
        }
    }

    .gh-list {
        margin-top: .5rem;

        &__item {
            display: flex;
            align-items: baseline;

            &::before {
                flex-shrink: 0;
                margin-right: 5px;
                content: '\f00c';
                font-family: 'FontAwesome';
            }
        }
    }

    .course-summary {
        font-size: 1.2rem;
    }

    .detail__content {
        font-size: 1.2rem;
    }

    .detail__content p {
        font-size: 1.2rem;
    }

    .course-actions {
        .gh-button-redesign--cta {
            background: #00c478;
        }

        .register-link {
            font-size: .9rem;

            a {
                color: $brand-primary;
                font-weight: 500;
            }
        }
    }

    .widget {
        padding: 1rem;
        background: #fff;

        &--plain {
            color: $pallete-dark-blue;
            background: transparent;
            margin-top: 1rem;
            padding-left: 0;
        }

        &__heading {
            margin-bottom: 1rem;
            font-size: 1.2rem;
        }
    }

    .gh-page__main {
        .course-actions {
            display: none;
        }

        .el-tabs__content {
            padding-top: 1rem;
        }

        .el-tab-pane {
            [class^='col'] {
                margin-bottom: 1rem;
            }
        }

        .collection__details {
            margin-top: 2rem;
        }
    }

    .gh-page__sidebar {
        max-width: 340px;
        margin: 2rem auto;

        .course-actions {
            .buttons {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
            }

            .gh-button-redesign {
                margin-bottom: .5rem;
                flex-basis: 100%;
            }
        }

        .course-features {
            margin-top: 1.5rem;
            background: #fff;
            box-shadow: -5px 1px 23px -4px rgba(0,0,0,0.2);
            border-radius: 2px;

            .gh-table {
                width: 100%;
                border-collapse: collapse;
            }

            .feature {
                color: $pallete-dark-blue;

                td {
                    padding: 1rem 0;
                }

                &__icon {
                    width: 30px;
                }

                &__label {
                    max-width: 120px;
                    font-weight: 600;
                }

                &__data {
                    font-weight: 600;
                    padding-left: 1rem !important;
                    a {
                        color: $brand-primary;
                    }
                }
            }
        }

        .gh-notice--collection {
            .gh-notice__icon {
                display: none;
            }
        }
    }

    .gh-course-collection {
        margin-bottom: 3rem;
    }

    @include m-respond-to('extra-small') {
        .gh-course-listing {
            &__item {
                flex-basis: 50%;
                flex-grow: 0;
            }
        }
    }

    @include m-respond-to('tablet') {
        .gh-page__main {
            .course-actions {
                display: flex;
                margin-top: 2rem;
                text-align: left;
                flex-wrap: wrap;
                .gh-button-redesign--cta {
                    display: block;
                    width: 100%;
                    max-width: 250px;
                    background: #00c478;
                }
            }
        }

        .gh-page__sidebar {
            margin-top: 0;
        }


        .course-features {
            .feature {
                &__label {
                    max-width: 100px;
                }
            }
        }

        .gh-course {
            padding: 0 20px;
        }

        .gh-related-courses {
            .detail__heading {
                padding-left: 20px;
            }
            .row {
                margin-left: 1rem;
                .col-md-3 {
                    padding-left: .4rem;
                    padding-right: 1rem;
                }
            }
        }
    }

    @include m-respond-to('notebook') {
        .gh-page__main {
            padding-right: 4rem;
        }

        .gh-course-listing {
            margin-top: 2rem;
            margin-left: -.5rem;
            margin-right: -1rem;

            &__item {
                max-width: 33%;
                flex-grow: 0;
                flex-basis: 33%;
            }
        }
    }
}
