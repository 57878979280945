$footer-top-background: #343841;

.gh-footer {
    font-family: proxima-nova, sans-serif;
    font-weight: 500;
}

.gh-footer__top {
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: #fff;
    background: $footer-top-background;

    .menu {
        margin-bottom: 2rem;
    }

    .mha-contact {
        color: #fff;
        font-size: 1.1rem;

        + .mha-contact {
            margin-top: 1rem;
        }
    }
}

.gh-footer__heading {
    margin-bottom: 1rem;
    color: $brand-primary;
    font-weight: 700;
    font-size: 1.2rem;
    text-align: left;
}

.gh-footer__menu {
    padding: 0;

    p {
        line-height: 1.5;
    }

    li + li {
        margin-top: .5rem;
    }
}

.gh-footer__link {
    display: block;
    padding: 5px 0;
    color: #fff;
    background: transparent;
    font-weight: 500;
    font-size: 1.1rem;
    font-family: inherit;
    transition: .3s;
    cursor: pointer;

    &:hover {
        color: $brand-primary;
        background: transparent;
    }
}

.gh-footer__logo {
    display: block;
    max-width: 120px;
    margin-top: 1rem;
}

.gh-footer__bottom {
    padding-bottom: 1rem;
    background: #F3F3F3;

    .gh-list {
        display: flex;
        justify-content: center;

        &__icon {
            display: block;
        }
    }

    .cards {
        .gh-list {
            color: #c0c1c3;
            font-size: 2.5rem;

            .gh-list__item {
                + .gh-list__item {
                    margin-left: 10px;
                }
            }
        }
    }

    .terms {
        margin-top: 1rem;

        .gh-list {
            font-size: 1.2rem;
        }

        .gh-list__item {
            + .gh-list__item {
                margin-left: 1rem;
            }
        }

        .gh-list__link {
            color: #2c3138;
        }
    }

    .copyrights {
        display: flex;
        flex-flow: row wrap;
        margin-top: .5rem;
        color: #575b61;
        font-weight: 500;
        font-size: .9rem;
        text-align: center;

        span {
            flex-basis: 100%;
        }

        a {
            color: inherit;
        }
    }

    @include m-respond-to('tablet') {
        padding-bottom: 0;

        .cards {
            .gh-list {
                justify-content: flex-start;
            }
        }

        .terms {
            margin-top: 0;

            .gh-list {
                justify-content: flex-end;
            }
        }

        .copyrights {
            margin-top: 0;
            text-align: right;
        }
    }

    @include m-respond-to('medium') {
        .copyrights {
            flex-flow: row;
            justify-content: flex-end;

            span {
                flex-basis: auto;

                + span {
                    margin-left: 5px;
                }
            }
        }
    }
}
