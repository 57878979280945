b,
strong {
    font-weight: 900;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    line-height: 1;
}

h1 {
    font-size: 3.4rem;
}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 2.8rem;
}

h4 {
    font-size: 2.2rem;
}

h5 {
    font-size: 1.8rem;
}

h6 {
    font-size: 1.6rem;
}

p {
    font-size: 1rem;
    line-height: 1.5;
}
