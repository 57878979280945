.gh-section {
    padding: 2.5rem 0;

    &__heading {
        margin: 0 0 2rem;
        color: $pallete-dark-grey;
        font-weight: 600;
        font-size: 2rem;
        text-align: center;
    }

    &__heading-large {
        font-size: 2.7rem;
    }

    &__body {
        font-size: 1.25rem;
        font-weight: 600;
    }
}
