.gh-card {
    position: relative;
    display: block;
    height: 100%;
    color: #2c3138;
    background: #fff;
    transition: box-shadow .6s cubic-bezier(0.165, 0.84, 0.44, 1);
    cursor: pointer;

    &__header {
        overflow: hidden;
    }

    &__image {
        overflow: hidden;
        width: 100%;
        height: 150px;
        margin: 0;
        background-image: url('/images/courses/placeholder.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: .3s;
    }

    &__body {
        padding: 10px;
    }

    &__footer {
        padding: 10px;
    }
}

//==================================================
//                 [-- PROGRAM CARD --]
//==================================================
.gh-card--program {
    transition: box-shadow .3s, transform .3s;
    border: solid 0 white;
    border-radius: 2px;
    box-shadow: 0px 0px 5px 2px rgba(222, 223, 224, 0.5);

    .gh-card__header {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }

    figure {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }

    .program {
        &__title {
            display: block;
            margin-bottom: 1rem;
            padding-left: 5px;
            color: $pallete-dark-blue;
            font-family: proxima-nova, sans-serif;
            font-weight: 700;
            font-size: 1.1rem;
            line-height: 1;
            word-break: normal;
        }

        &__detail {
            color: #5D6067;
            font-family: freight-sans-pro, sans-serif;
            font-weight: 600;
        }

        &__label {
            color: #5D6067;
            font-family: freight-sans-pro, sans-serif;
            font-weight: 600;
        }

        &__icon {
            color: #575b61;
        }
    }

    @include m-respond-to('small') {
        .program {
            &__icon {
                display: none;
            }

            &__label {
                display: block;
            }
        }
    }

    @include m-respond-to('tablet') {
        .program {
            &__title {
                margin-bottom: 1rem;
                line-height: 1.2;
            }
        }
    }

    @include m-respond-to('notebook') {
        &:hover {
            transform: translate3d(0, -5px, 0);
            box-shadow: 0 0 20px rgba(0, 0, 0, .25);
        }

        .gh-card__image {
            max-height: 140px;
        }
    }
}
