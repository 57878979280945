.gh-dashboard--course-content {
    min-height: 200px;
    
    .text-center {
        text-align: center;
    }

    .gh-page__heading {
        margin-bottom: 2rem;
        color: #2c3138;
        font-weight: 700;
        font-size: 2rem;
        text-align: left;
    }

    .gh-section {
        padding: 0;

        + .gh-section {
            margin-top: 2rem;
        }

        &__heading {
            margin-bottom: 1rem;
            font-size: 1.7rem;
            text-align: left;
        }

        &__content {
            + .gh-section__content {
                margin-top: 2rem;
            }
        }
    }

    .overview {
        margin: 2rem 0;
        padding: 1rem;
        color: #565a60;
        background: #fff;
        font-weight: 500;

        .gh-section__heading {
            margin-bottom: 1rem;
            color: #2c3138;
            font-weight: 700;
            font-size: 1.5rem;
        }

        .gh-button {
            margin-top: 2rem;
        }
    }

    .video {
        padding: 1rem;
        background: #fff;
    }

    .gh-chapter {
        overflow: auto;
        padding: 1rem;
        background: #fff;

        p  {
            font-weight: 400;
            word-wrap: break-word;

            + p {
                margin-top: 1rem;
            }

        }

        ul {
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-left: 2rem;
            list-style-type: disc;
        }

        ul,
        ol {
            font-weight: 400;
            li {
                word-wrap: break-word;
                + li {
                    margin-top: .5rem;
                }
            }
        }

        h1,
        h2 {
            margin: 2rem 0 1rem;
            color: #474f5b;
            font-size: 1.5rem;
        }

        h3 {
            margin: 1rem 0;
            color: #2f3138;
            font-size: 1.2rem;
            line-height: 1.3;
        }

        h4 {
          font-size:  1rem;
          margin: 0.8rem 0;
        }

        h5 {
            font-size:  .9rem;
            font-style: italic;
            margin: 0.8rem 0;
        }

        h1:first-child,
        h2:first-child,
        h3:first-child {
            margin-top: 0;
        }

        a {
            color: $brand-secondary;
            font-weight: 500;
            word-wrap:break-word;
        }

        img {
            display: block;
            width: auto;
            margin: 2rem auto;
        }

        object {
           display: block;
           margin: 0 auto;
           margin-top:30px;
           margin-bottom:30px;
        }

        iframe {
           display: block;
           margin: 0 auto;
           margin-top:30px;
           margin-bottom:30px;
        }

        .dialog {
            background-color: #d0e8f9;
            border: #24c6dc 3px solid;
            padding: 15px;
            margin:4%;
        }

        .dialog-green {
            background-color: #d0f9e1;
            border: #24dc3a 3px solid;
            padding: 15px;
            margin:4%;
        }

        .case_study {
            background-color: #d0e8f9;
            border: #24c6dc 3px solid;
            padding: 15px;
            margin:4%;
        }

        .insights {
            background-color: #d0e8f9;
            border: #24c6dc 3px dashed;
            padding: 15px;
            margin:4%;
        }

        .centered {
            text-align: center;
        }

        table{
            width: 100% !important;
            margin: 1rem auto;
            word-wrap: break-word;
        }

        table.coursetable {
            display: block;
            width: fit-content;
            margin: 0 auto;
            margin-top: 4%;
            margin-bottom:30px;
        }

        td.classtable_header {
            background-color: #5eb0d8;
            color: #FFFFFF;
            font-weight: bold;
        }

        td.classtable_content {
            background-color: #eef5fa;
            text-align: left;
        }

        td.classtable_subtle{
            background-color:#d0e8f9;
        }

        ul.no-bullets {
            list-style-type: none;
        }

        .chapter__table-wrapper {
            overflow: auto;
        }

        .chapter__table {
            border-collapse: collapse;

            th, td {
                padding: .5rem;
                border: 2px solid;
            }
        }

        .is-center {
            text-align: center;
        }

        .gh-hightlight-box-lightblue {
            margin: 2rem 0;
            padding: 2rem;
            background: $brand-primary;
            color: white;
            h2, h3 {
                color: white;
            }
        }
    }

    .actions {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
    }

    .gh-course-download-exercise {
        margin: 1rem 0;
    }

    .gh-course-download-exercise-link {
        display: inline-flex;
        align-items: center;
        padding: .5rem 1rem;
        background: #f3f3f3;
        font-size: 16px;

        .gh-course-download-exercise-label {
            font-weight: bold;
        }

        .gh-course-download-exercise-hint {
            color: #8391a5;
        }
    }

    @media screen and (min-width: 768px) {
        .gh-course-download-exercise-link {
            &::before {
                content: '';
                width: 40px;
                height: 40px;
                margin-right: 1rem;
                background: url('/images/courses/pdf-icon.svg') no-repeat center center;
                background-size: cover;
            }
        }
    }
}
