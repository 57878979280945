.gh-page--training {
    font-family: Catamaran,sans-serif;

    
    a {
        font-family: Catamaran,sans-serif;
    }
    
    button {
        font-family: Catamaran,sans-serif;
    }

    p {	
        font-size: 18px;	
    }

    .grecaptcha-badge {
        z-index: 2;
    }
    .gh-bold {
        font-weight: 700;
    }

    .container {
        padding: 0 1rem;
    }

    .gh-intro {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.4;
    }

    .gh-section {
        padding: 3rem 1rem;

        &__heading {
            margin-bottom: 1rem;
        }

        &__subheading {
            margin-bottom: 1rem;
            color: #2a3038;
            font-size: 1.8rem;
            text-align: center;
        }

        &__intro {
            color: #2c3138;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
            font-size: 1.3rem;
            text-align: center;
            font-weight: 500;
        }
    }

    .has-gradient {
        @extend %gradient-venice-blue;
    }

    .cta {
        text-align: center;

        .gh-section__heading,
        .gh-section__subheading,
        .gh-button {
            color: #fff;
        }

        .gh-button {
            border: 1px solid #fff;
            background: transparent;
        }
    }

    @media screen and (min-width: '768px') {
        .c-form__group {
            display: flex;
            justify-content: space-between;
        }
    
        .c-form__item {
            max-width: 48%;
            flex-basis: 48%;
        }
    
        .c-form__tnc {
            max-width: 100%;
            flex-basis: 100%;
        }
    }
    .gh-footer {
        .container {
            @media screen and (max-width: 64em) and (min-width: 768px) {
                width: 98%;
            }

            @media screen and (max-width: 767px) {
                padding: 20px;
            }
        }

        .gh-footer__bottom {
            padding: 1rem;
        }
    }

    @include m-respond-to('extra-small') {
    }

    @include m-respond-to('small') {
    }

    @include m-respond-to('tablet') {
    }

    @include m-respond-to('notebook') {
        .gh-section {
            padding: 3.5rem 1rem;
        }
    }

    @include m-respond-to('medium') {
    }
}
